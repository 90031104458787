<template>
  <ElementModal @close="this.$emit('close')">
      <template v-slot:title>
        <input type="text" class="title" v-model="displayedElement.name" >
      </template>
    
    <template v-slot:content>
      <h2><font-awesome-icon icon="align-left" /> {{ translate('Description') }}</h2>
      <textarea name="description" v-model="displayedElement.description" cols="40" rows="5" ></textarea>

      <h2><font-awesome-icon icon="comment" /> {{ translate('Prophecies') }}</h2>
      <input type="text" class="with-button" v-model="tempProphecy"  :placeholder="translate('New Prophecy')" @keyup.enter="addProphecy(tempProphecy)"/>
      <div class="button add-button" @click="addProphecy(tempProphecy)"><font-awesome-icon icon="plus" /></div>
      <div class="clear">&nbsp;</div>
      <div v-for="prophecy in displayedElement.prophecies" :key="prophecy.name" class="pill" >
        <div>{{ prophecy.date }} - {{ prophecy.name }}<span class="delete" @click="deleteProphecy(prophecy)"> x </span>
        </div>
      </div>

      <h2><font-awesome-icon icon="star" /> {{ translate('Highlights') }}</h2>
      <input type="text" class="with-button" v-model="tempHighlight" :placeholder="translate('New Highlight')"  @keyup.enter="addHighlight(tempHighlight)" />
      <div class="button add-button" @click="addHighlight(tempHighlight)"><font-awesome-icon icon="plus" /></div>
      <div class="clear">&nbsp;</div>
      <div v-for="highlight in displayedElement.highlights" :key="highlight.name" class="pill" >
        <div>{{ highlight.date }} - {{ highlight.name }}<span class="delete" @click="deleteHighlight(highlight)"> x </span>
        </div>
      </div>

      <h2><font-awesome-icon icon="shoe-prints" /> {{ translate('Next Steps') }}</h2>
      <input type="text" class="with-button" v-model="tempStep"  :placeholder="translate('New Step')"  @keyup.enter="addStep(tempStep)" />
      <div class="button add-button" @click="addStep(tempStep)"><font-awesome-icon icon="plus" /></div>
      <div class="clear">&nbsp;</div>
      <div v-for="step in displayedElement.nextsteps" :key="step.name" class="pill">
        <div>
          <input type="checkbox" v-model="step.checked" />{{ step.name }}<span class="delete" @click="deleteStep(step)"> x </span>
        </div>
      </div>

      <h2><font-awesome-icon icon="exclamation-circle" /> {{ translate('Next Calls') }}</h2>
      <input type="text" class="with-button" v-model="tempCall" :placeholder="translate('New Call')"  @keyup.enter="addCall(tempCall)" />
      <div class="button add-button" @click="addCall(tempCall)"><font-awesome-icon icon="plus" /></div>
      <div class="clear">&nbsp;</div>
      <div v-for="call in displayedElement.nextcalls" :key="call.name" class="pill">
        <div>
          <input type="checkbox" v-model="call.checked" />{{ call.name }}<span class="delete" @click="deleteCall(call)"> x </span>
        </div>
      </div>

    </template>

    <template v-slot:sidebar>
      <h3>{{ translate('Actions') }}</h3>
      <div class="sidebar_button" @click="deleteElement"><font-awesome-icon icon="trash" />  {{ translate('Delete') }}</div>
      <div v-if="displayedElement.phase!='archive'" class="sidebar_button" @click="archiveElement"><font-awesome-icon icon="boxes" />  {{ translate('Archive') }}</div>
      <div v-else class="sidebar_button" @click="restoreElement"><font-awesome-icon icon="undo" />  {{ translate('Restore') }}</div>
    
      <h3>{{ translate('Skills of a Desciplemaker') }}</h3>
      <ul class="desciplemakerskills">
        <li><h4 v-if="connectSkills.length > 0">{{ translate('Connect') }}</h4></li>
        <li v-for="skill in connectSkills" :key="skill.id">
            <input type="checkbox" v-model="displayedElement.checkedSkills" :value="skill.id"/>{{ skill.name }}
        </li>
        <li><h4 v-if="winSkills.length > 0">{{ translate('Win') }}</h4></li>
        <li v-for="skill in winSkills" :key="skill.id">
            <input type="checkbox" v-model="displayedElement.checkedSkills" :value="skill.id"/>{{ skill.name }}
        </li>
        <li><h4 v-if="buildSkills.length > 0">{{ translate('Build') }}</h4></li>
        <li v-for="skill in buildSkills" :key="skill.id">
            <input type="checkbox" v-model="displayedElement.checkedSkills" :value="skill.id"/>{{ skill.name }}
        </li>
        <li><h4 v-if="trainSkills.length > 0">{{ translate('Train') }}</h4></li>
        <li v-for="skill in trainSkills" :key="skill.id">
            <input type="checkbox" v-model="displayedElement.checkedSkills" :value="skill.id"/>{{ skill.name }}
        </li>
        <li><h4 v-if="sendSkills.length > 0">{{ translate('Send') }}</h4></li>
        <li v-for="skill in sendSkills" :key="skill.id">
            <input type="checkbox" v-model="displayedElement.checkedSkills" :value="skill.id"/>{{ skill.name }}
        </li>
      </ul>

      <h3>{{ translate('Suggested Programs') }}</h3>
      <ul v-if="displayedElement.phase == 'connect'" >
        <li v-for="program in arrProgramConnect" :key="program.name">{{ program.name }}</li>
      </ul>
      <ul v-if="displayedElement.phase == 'win'" >
        <li v-for="program in arrProgramWin" :key="program.name">{{ program.name }}</li>
      </ul>
      <ul v-if="displayedElement.phase == 'build'" >
        <li v-for="program in arrProgramBuild" :key="program.name">{{ program.name }}</li>
      </ul>
      <ul v-if="displayedElement.phase == 'train'" >
        <li v-for="program in arrProgramTrain" :key="program.name">{{ program.name }}</li>
      </ul>
      <ul v-if="displayedElement.phase == 'send'" >
        <li v-for="program in arrProgramSend" :key="program.name">{{ program.name }}</li>
      </ul>
    </template>
  </ElementModal>
</template>

<script>
import ElementModal from "./ElementModal.vue";

export default {
  data() {
    return {
      tempStep: "",
      tempCall: "",
      tempProphecy: "",
      tempHighlight: "",
      currentPhase: "",
    };
  },
  components: {
    ElementModal,
  },
  props: [
    "displayedElement",
    "currentArrow",
    "arrProgramConnect",
    "arrProgramWin",
    "arrProgramBuild",
    "arrProgramTrain",
    "arrProgramSend", 
    'translate',
  ],
  emits: ['delete-element', 'archive-element', 'close'],
  methods: {
    deleteStep: function (step) {
      this.displayedElement.nextsteps = this.displayedElement.nextsteps.filter(
        (item) => {
          return step !== item;
        }
      );
    },
    addStep: function (step) {
      if (step) {
        if (!this.displayedElement.nextsteps.includes({ name: step })) {
          this.displayedElement.nextsteps.push({ name: step, checked: false });
        }
        this.tempStep = "";
      }
    },
    addCall: function (call) {
      if (call) {
        if (!this.displayedElement.nextcalls.includes({ name: call })) {
          this.displayedElement.nextcalls.push({ name: call, checked: false });
        }
        this.tempCall = "";
      }
    },
    deleteCall: function (call) {
      this.displayedElement.nextcalls = this.displayedElement.nextcalls.filter(
        (item) => {
          return call !== item;
        }
      );
    },
    addProphecy: function (prophecy) {
      if (prophecy) {
        if (!this.displayedElement.prophecies.includes({ name: prophecy })) {
          let today = new Date()
          let month = today.getMonth() + 1
          let todayFormated = today.getDate() + "." + month + "." + today.getFullYear()
          this.displayedElement.prophecies.push({ name: prophecy, date: todayFormated });  
        }
        this.tempProphecy = "";
      }
    },
    deleteProphecy: function (prophecy) {
      this.displayedElement.prophecies = this.displayedElement.prophecies.filter(
        (item) => {
          return prophecy !== item;
        }
      );
    },
    addHighlight: function (highlight) {
      if (highlight) {
        if (!this.displayedElement.highlights.includes({ name: highlight })) {
          let today = new Date()
          let month = today.getMonth() + 1
          let todayFormated = today.getDate() + "." + month + "." + today.getFullYear()
          this.displayedElement.highlights.push({ name: highlight, date: todayFormated });
        }
        this.tempHighlight = "";
      }
    },
    deleteHighlight: function (highlight) {
      this.displayedElement.highlights = this.displayedElement.highlights.filter(
        (item) => {
          return highlight !== item;
        }
      );
    },

    deleteElement: function () {
      this.$emit('delete-element', this.displayedElement.id);
      this.$emit('close');
    },
    archiveElement: function () {
      this.$emit("archive-element", this.displayedElement.id);
      this.$emit('close');
    },
    restoreElement: function () {
      this.displayedElement.phase = "connect";
      this.$emit('close');
    },
  },
  computed: {
    connectSkills: function () {
      if (this.currentArrow.product.skills) {
        return this.currentArrow.product.skills.filter(function (skill) { return skill.phase == 1 })
      }
      else 
        return []
    },
    winSkills: function () {
      if (this.currentArrow.product.skills) {
        return this.currentArrow.product.skills.filter(function (skill) { return skill.phase == 2 })
      }
      else 
        return []
    },
    buildSkills: function () {
      if (this.currentArrow.product.skills) {
        return this.currentArrow.product.skills.filter(function (skill) { return skill.phase == 3 })
      }
      else 
        return []
    },
    trainSkills: function () {
      if (this.currentArrow.product.skills) {
        return this.currentArrow.product.skills.filter(function (skill) { return skill.phase == 4 })
      }
      else 
        return []
    },
    sendSkills: function () {
      if (this.currentArrow.product.skills) {
        return this.currentArrow.product.skills.filter(function (skill) { return skill.phase == 5 })
      }
      else 
        return []
    }
  },






};
</script>

<style>
</style>