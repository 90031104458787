import { createApp } from 'vue'
import { VueFire, VueFireAuth, VueFireFirestoreOptionsAPI } from 'vuefire'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPalette } from '@fortawesome/free-solid-svg-icons'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faShoePrints } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faHome)
library.add(faArrowRight)
library.add(faUser)
library.add(faTrash)
library.add(faBoxes)
library.add(faSave)
library.add(faPlus)
library.add(faPalette)
library.add(faComment)
library.add(faShoePrints)
library.add(faAlignLeft)
library.add(faExclamationCircle)
library.add(faHistory)
library.add(faUndo)
library.add(faClock)
library.add(faBullseye)
library.add(faTools)
library.add(faSignInAlt)
library.add(faUserPlus)
library.add(faEdit)
library.add(faStar)
library.add(faInfo)
library.add(faPencil)



createApp(App).use(router, VueFire, { modules: [VueFireFirestoreOptionsAPI, VueFireAuth()
] }).component('font-awesome-icon', FontAwesomeIcon).mount('#app');