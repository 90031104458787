<template>

<div class="element_list">
  
  <draggable 
    v-model="elements" 
    :group="type" 
    :id="phase" 
    class="drop-zone" 
    @end="onEnd" 
    @change="onChange" 
    item-key="id">
    <template #item="{element}">
      <div @click="showDetails(element)" class="draggable-item">{{element.name}}</div>
    </template>
  </draggable>

  <span class="footer" v-if="type === 'people'">   
    <input type="text" v-model="newElement" required :placeholder="translate('New Person')" @keyup.enter="addElement" />
    <div class="button" @click="addElement"><font-awesome-icon icon="plus" /></div>
  </span>
  <span class="footer" v-else>
    <input type="text"  v-model="newElement" required :placeholder="translate('New Program')" @keyup.enter="addElement" />
    <div class="button" @click="addElement"><font-awesome-icon icon="plus" /></div>
  </span>
</div>

</template>

<script>

import draggable from "vuedraggable";

export default {
  name: "ElementList",
  components: {
    draggable,
  },
  props: [
    "phase",
    "type",
    "arrElements",
    'translate',
  ],
  emits: ['add-element', 'move-element', 'show-details'],
  data() {
    return {
      newElement: "",
      movedElement: null,
      elements: [],
      list1: [{ name: "Drag Me!" }],
    };
  },
  created () {
    if (typeof this.arrElements !== 'undefined') {
      this.elements = this.arrElements;
    }
  },
  watch: {
    arrElements (newValue) {
      if (typeof newValue !== 'undefined') {
        this.elements = newValue
      }
    },
  },
  methods: {
    addElement: function () {
      if (this.newElement) {
        this.$emit("add-element", this.phase, this.newElement );
        this.newElement = "";
      }
    },
    showDetails: function (element) {
      this.$emit("show-details", element, this.type);
    },
    onChange: function (evt) {
      if (evt.moved) {  
        this.movedElement = evt.moved.element;
      }
      if (evt.removed) {   
        this.movedElement = evt.removed.element;
      }
    },
    onEnd: function (evt) {
      if (this.movedElement) {
        this.$emit("move-element", this.movedElement, evt.to.id, evt.from.id, evt.newDraggableIndex, evt.oldDraggableIndex);
        this.movedElement = null
      } 
    },
  },
};
</script>

<style scoped>
.element_list {
    width: 100%;
    height: 100%;
    display: flex;


    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;

}
.drop-zone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
    height: 100%;
}
.programList .drop-zone {
    justify-content: flex-start;
}
.peopleList .drop-zone {
    justify-content: flex-end;
}
.draggable-item {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgb(9 30 66 / 25%);
    cursor: pointer;
    padding: 5px 10px;
    margin: 3px;
    width: fit-content;
    height: fit-content;
}
.draggable-item:hover {
    background-color: hsl(228, 14%, 97%);     
}
.footer {
    max-height: 38px;
    display: flex;
}
.programList .footer {
    justify-content: flex-start;
}
.peopleList .footer {
    justify-content: flex-end;
}

.footer input {
    color: #172b4d;
    border-radius: 3px;
    width: calc(100% - 35px);
    font-size: 0.8rem;
    border: none;
    padding: 5px;
    margin: 5px;
    cursor: text;
    background-color: hsla(0,0%,100%,.3); 
}
.footer input:hover {
    background-color: rgba(9,30,66,.08);
}
.footer .button {
    margin: 5px 5px 5px 0;
    width: 30px;
    font-size: 0.8rem;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    background-color: hsla(0,0%,100%,.3); 
  }
  .footer .button:hover {
    background-color: var(--arrow-background-color);
  }

  .footer .button svg {
    height: 0.8rem;
  }

  @media (max-width: 600px) {
  .footer .button {
    padding: 5px;
  }
}

</style>