<template>
  <ElementModal @close="this.$emit('close')">
    <template v-slot:title>
      <h1>{{ translate('Product') }}</h1>      
    </template>

    <template v-slot:content>
      <h2><font-awesome-icon icon="align-left" /> {{ translate('Description') }}</h2>
      <textarea name="description" v-model="product.description" cols="40" rows="5" :placeholder="translate('Describe your product')" ></textarea>

      <h2><font-awesome-icon icon="tools" /> {{ translate('Skills') }}</h2>
      <input type="text" class="with-button new-skill" v-model="tempSkill"  :placeholder="translate('New Skill')" />

      <select v-model="tempPhase">
          <option value="" disabled selected >{{ translate('Select') }}...</option>
          <option value="1">{{ translate('Connect') }}</option>
          <option value="2">{{ translate('Win') }}</option>
          <option value="3">{{ translate('Build') }}</option>
          <option value="4">{{ translate('Train') }}</option>
          <option value="5">{{ translate('Send') }}</option>
      </select>

      <div class="button add-button" @click="addSkill()"><font-awesome-icon icon="plus" /></div>
      <div class="clear">&nbsp;</div>
      <div v-for="skill in product.skills" :key="skill.id" class="pill">
        <div>
          {{ skill.name }} ({{ translate(getPhase(skill.phase)) }})<span class="delete" @click="deleteSkill(skill)"> x </span>
        </div>
      </div>
    </template>
  </ElementModal>
</template>

<script>
import ElementModal from "./ElementModal.vue";

export default {
  components: {
    ElementModal,
  },
  data() {
    return {
      description: "",
      skills: [],
      tempSkill: "",
      tempPhase: "",
    };
  },
  props: [
    "product",
    'translate',
    ],
  methods: {
    closeProduct: function () {
      this.$emit("close");
    },
    addSkill() {
      if (this.tempSkill) {
          this.product.skills.push({
            id: Date.now(),
            name: this.tempSkill,
            phase: this.tempPhase,
            checked: false,
          });
        this.tempSkill = "";
        this.tempPhase = "";

        this.product.skills.sort(function (a, b) {
          return a.phase - b.phase;
        });
      }
    },
    deleteSkill(skill) {
      this.product.skills = this.product.skills.filter((item) => {
        return skill !== item;
      });
    },
    getPhase(phaseID) {
      switch (phaseID) {
        case '1':
          return 'Connect';
        case '2':
          return 'Win';
        case '3':
          return 'Build';
        case '4':
          return 'Train';
        case '5':
          return 'Send';
      };
    },
  },
};
</script>

<style>

</style>