<template>
  <ElementModal @close="this.$emit('close')">
    <template v-slot:title>
        <input type="text" class="title" v-model="displayedElement.name" >
    </template>

    <template v-slot:content>
      <h2><font-awesome-icon icon="align-left" /> {{ translate('Description') }}</h2>
      <textarea name="description" v-model="displayedElement.description" cols="40" rows="5" ></textarea>

      <h2><font-awesome-icon icon="bullseye" /> {{ translate('Goal (Mainpurpose)') }}</h2>
      <input type="text" v-model="displayedElement.goal"  />
      <div class="clear">&nbsp;</div>

      <h2><font-awesome-icon icon="clock" /> {{ translate('Interval') }}</h2>
      <select v-model="displayedElement.interval" >
        <option value="" disabled selected >{{ translate('Select') }}...</option>
        <option value="weekly">{{ translate('weekly') }}</option>
        <option value="twoweekly">{{ translate('every other week') }}</option>
        <option value="monthly">{{ translate('monthly') }}</option>
        <option value="yearly">{{ translate('once a year') }}</option>
        <option value="other">{{ translate('other') }}</option>
      </select>
      <div class="clear">&nbsp;</div>
    </template>

    <template v-slot:sidebar> 
      <h3>{{ translate('Actions') }}</h3>
      <div class="sidebar_button" @click="deleteElement"><font-awesome-icon icon="trash" />  {{ translate('Delete') }}</div>
      <div v-if="displayedElement.phase!='archive'" class="sidebar_button" @click="archiveElement"><font-awesome-icon icon="boxes" />  {{ translate('Archive') }}</div>
      <div v-else class="sidebar_button" @click="restoreElement"><font-awesome-icon icon="undo" />  {{ translate('Restore') }}</div>
    </template>

  </ElementModal>
</template>

<script>
import ElementModal from "./ElementModal.vue";

export default {
  components: {
    ElementModal,
  },
  props: [
    "displayedElement",
    'translate',
    ],
  methods: {
    deleteElement: function () {
      this.$emit('delete-element', this.displayedElement.id);
      this.$emit('close');
    },
    archiveElement: function () {
      this.$emit("archive-element", this.displayedElement.id);
      this.$emit('close');
    },
    restoreElement: function () {
      this.displayedElement.phase = "connect";
      this.$emit('close');
    },
  }
};
</script>

<style>
</style>