<template>

  <nav class="clo_nav arrow_nav">
    <div class="nav_left">
      <div class="nav_item titel">
          <input type="text" v-model="currentArrow.title" />
      </div>
 
      <div class="nav_item" :data-tooltip="translate('Color')" @click="() => { this.isShowingColorpicker = true;} ">
          <span><font-awesome-icon icon="palette" /></span><p>{{ translate('Color') }}</p>
      </div>
      <!--
     <div class="nav_item" :data-tooltip="translate('Clear')" @click="clearArrow">
          <span><font-awesome-icon icon="trash" /></span><p>{{ translate('Clear') }}</p>
      </div> 
    -->
      <div class="nav_item" v-if="currentUser"  :data-tooltip="translate('New')" @click="newArrow">
          <span><font-awesome-icon icon="plus" /></span><p>{{ translate('New') }}</p>
      </div>
    </div>

    <div class="nav_right">
       <div class="nav_item"  :data-tooltip="translate('Archives')" @click="() => { this.isShowingArchive = true;} ">
          <span><font-awesome-icon icon="boxes" /></span><p>{{ translate('Archives') }}</p>
       </div>
    </div>
  </nav>

      <SlideInMenu :display_backdrop="true" :class="{ active: isShowingArchive }" @close="isShowingArchive=false">
      <template v-slot:title>{{ translate('Archives') }}</template>
      <template v-slot:content>
        <h2>{{ translate('People') }}</h2>
        <div  v-for="element in PeopleArchive" :key="element.id">
          <div class="archive-item" @click="showDetails(element, 'people')">{{element.name}}</div>
          <span class="link" @click="element.phase='connect'">{{ translate('Restore') }}</span> - <span class="link"  @click="deletePeople(element.id)">{{ translate('Delete') }}</span>
        </div>     
        <h2>{{ translate('Program') }}</h2>
        <div  v-for="element in ProgramArchive" :key="element.id">
          <div class="archive-item" @click="showDetails(element, 'program')">{{element.name}}</div>
          <span class="link"  @click="element.phase='connect'">{{ translate('Restore') }}</span> - <span  class="link" @click="deleteProgram(element.id)">{{ translate('Delete') }}</span>
        </div>
      </template>
    </SlideInMenu>

    <SlideInMenu align="left" :display_backdrop="true" :class="{ active: isShowingColorpicker }" @close="isShowingColorpicker=false">
      <template v-slot:title>{{ translate('Change Color') }}</template>
      <template v-slot:content>
        <h2>{{ translate('Background Color') }}</h2>
        <div class="colorpickerButton colopicker_1" @click="changeBackgroundColor"></div>
        <div class="colorpickerButton colopicker_2" @click="changeBackgroundColor"></div>
        <div class="clear"></div>
       <h2>{{ translate('Arrow Color') }}</h2>
        <div class="colorpickerButton colopicker_3" @click="changeFontColor"></div>
        <div class="colorpickerButton colopicker_4" @click="changeFontColor"></div>
        <div class="colorpickerButton colopicker_5" @click="changeFontColor"></div>
         <div class="colorpickerButton colopicker_6" @click="changeFontColor"></div>
         <div class="colorpickerButton colopicker_7" @click="changeFontColor"></div>
         <div class="colorpickerButton colopicker_8" @click="changeFontColor"></div>
         <div class="colorpickerButton colopicker_9" @click="changeFontColor"></div>
        <div class="clear"></div>
      </template>
    </SlideInMenu>
  

  <div class="arrow">
 
        <div class="target">
          <div class="purpose" @click="() => { this.isShowingPurpose = true;}" >
            <h2>{{ translate('Purpose') }} <span><font-awesome-icon icon="pencil" /></span></h2>
            <p v-if="currentArrow.purpose.description">{{ currentArrow.purpose.description }}</p>
            <p v-else> {{ translate('Describe your purpose') }}</p>
          </div>

          <div class="passion" @click="() => { this.isShowingPassion = true;}" >
            <h2>{{ translate('Passion') }} <span><font-awesome-icon icon="pencil" /></span></h2>
            <p v-if="currentArrow.passion.description">{{ currentArrow.passion.description }}</p>
            <p v-else> {{ translate('Describe your passion') }}</p>
         </div>
        </div>

         <div class="product_divider">&nbsp;</div>

          <div class="product" @click="() => { this.isShowingProduct = true;}" >
            <h2>{{ translate('Product') }} <span><font-awesome-icon icon="pencil" /></span></h2>
            <p v-if="currentArrow.product.description">{{ currentArrow.product.description }}</p>
            <p v-else> {{ translate('Describe your product') }}</p>
          </div>

      <div class="steps_container">
        <div class="step_divider">&nbsp;</div>

        <div class="step send">
          <span class="peopleList">
              
            <ElementList
                phase="send"
                :arrElements="PeopleSend"
                type="people" 
                :translate="translate"
                @add-element="addPeople" 
                @move-element="movePeople"
                @show-details="showDetails"
              />
              
          </span>
          <span class="process">{{ translate('SEND') }}<!--<span id="infobutton">i</span><span class="tooltip"  :tooltip="translate('Description of Send.')"></span>--></span>
          <span class="programList">
              <ElementList 
                      phase="send"  
                      :arrElements="ProgramSend" 
                      :translate="translate"
                      type="program" 
                      @add-element="addProgram" 
                      @move-element="moveProgram"
                      @show-details="showDetails" />
          </span>
        </div>

        <div class="step_divider">&nbsp;</div>

        <div class="step train">
          <span class="peopleList">
            <ElementList
                phase="train"
                :arrElements="PeopleTrain"
                type="people" 
                :translate="translate"
                @add-element="addPeople" 
                @move-element="movePeople"
                @show-details="showDetails"
              />
          </span>
          <span class="process">{{ translate('TRAIN') }}<!--<span id="infobutton">i</span><span class="tooltip"  :tooltip="translate('Description of Train.')"></span>--></span>
           <span class="programList">
              <ElementList 
                      phase="train"  
                      :arrElements="ProgramTrain" 
                      :translate="translate"
                      type="program" 
                      @add-element="addProgram" 
                      @move-element="moveProgram"
                      @show-details="showDetails" />
          </span>
        </div>

        <div class="step_divider">&nbsp;</div>

        <div class="step build">
          <span class="peopleList">
            <ElementList
                phase="build"
                :arrElements="PeopleBuild"
                type="people" 
                :translate="translate"
                @add-element="addPeople" 
                @move-element="movePeople"
                @show-details="showDetails"
              />
          </span>
          <span class="process">{{ translate('BUILD') }}<!--<span id="infobutton">i</span><span class="tooltip"  :tooltip="translate('Description of Build.')"></span>--></span>
          <span class="programList">
              <ElementList 
                      phase="build"  
                      :arrElements="ProgramBuild" 
                      :translate="translate"
                      type="program" 
                      @add-element="addProgram" 
                      @move-element="moveProgram"
                      @show-details="showDetails" />
          </span>
        </div>

        <div class="step_divider">&nbsp;</div>

        <div class="step win">
          <span class="peopleList">
            <ElementList
                phase="win"
                :arrElements="PeopleWin"
                type="people" 
                :translate="translate"
                @add-element="addPeople" 
                @move-element="movePeople"
                @show-details="showDetails"
              />
          </span>
          <span class="process">{{ translate('WIN') }}<!--<span id="infobutton">i</span><span class="tooltip"  :tooltip="translate('Description of Win.')"></span>--></span>
          <span class="programList">
              <ElementList 
                      phase="win"  
                      :arrElements="ProgramWin" 
                      :translate="translate"
                      type="program" 
                      @add-element="addProgram" 
                      @move-element="moveProgram"
                      @show-details="showDetails" />
          </span>
        </div>

        <div class="step_divider">&nbsp;</div>

        <div class="step connect">
          <span class="peopleList">
            <ElementList
                phase="connect"
                :arrElements="PeopleConnect"
                type="people" 
                :translate="translate"
                @add-element="addPeople" 
                @move-element="movePeople"
                @show-details="showDetails"
              />
          </span>
          <span class="process">{{ translate('CONNECT') }}<!--<span id="infobutton">i</span><span class="tooltip"  :tooltip="translate('Description of Connect.')"></span>--></span>
          <span class="programList">
              <ElementList 
                      phase="connect"  
                      :arrElements="ProgramConnect" 
                      :translate="translate"
                      type="program" 
                      @add-element="addProgram" 
                      @move-element="moveProgram"
                      @show-details="showDetails" />
          </span>
        </div>
      </div>
     
    <ElementDetailsProduct
      v-if="isShowingProduct"
      @close="isShowingProduct = false"
      :product="currentArrow.product"
      :translate="translate"
    />
    
  <ElementModal v-if="isShowingPurpose" @close="isShowingPurpose = false">
    <template v-slot:title>
      <h1>{{ translate('Purpose') }}</h1>      
    </template>

    <template v-slot:content>
      <h2><font-awesome-icon icon="align-left" /> {{ translate('Description') }}</h2>
      <textarea name="description" v-model="currentArrow.purpose.description" cols="40" rows="5" :placeholder="translate('Describe your purpose')"></textarea>
    </template>
  </ElementModal>

  <ElementModal v-if="isShowingPassion" @close="isShowingPassion = false">
    <template v-slot:title>
      <h1>{{ translate('Passion') }}</h1>      
    </template>

    <template v-slot:content>
      <h2><font-awesome-icon icon="align-left" /> {{ translate('Description') }}</h2>
      <textarea name="description" v-model="currentArrow.passion.description" cols="40" rows="5" :placeholder="translate('Describe your passion')"></textarea>
    </template>
  </ElementModal>


  <ElementDetailsPeople
    v-if="isShowingPeople"
    :displayedElement="displayedElement"
    :currentArrow="currentArrow"
    :arrProgramConnect="ProgramConnect"
    :arrProgramWin="ProgramWin"
    :arrProgramBuild="ProgramBuild"
    :arrProgramTrain="ProgramTrain"
    :arrProgramSend="ProgramSend"
    :translate="translate"
    @close="isShowingPeople = false"
    @delete-element="deletePeople"
    @archive-element="archivePeople"
  />
  <ElementDetailsProgram
    v-if="isShowingProgram"
    :displayedElement="displayedElement"
    :translate="translate"
    @close="isShowingProgram = false"
    @delete-element="deleteProgram"
    @archive-element="archiveProgram"
  />
  
</div>

</template> 

<script>
import ElementModal from "../components/ElementModal.vue";
import ElementDetailsProduct from "../components/ElementDetailsProduct.vue";
import ElementList from "../components/ElementList.vue";
import ElementDetailsProgram from "../components/ElementDetailsProgram.vue";
import ElementDetailsPeople from "../components/ElementDetailsPeople.vue";
import SlideInMenu from "../components/SlideInMenu.vue"
import useElement from "@/composables/useElement.js"; 
import { ref } from "@vue/reactivity";
import { watch } from '@vue/runtime-core';
import { toRefs } from 'vue';


export default {
  name: "Arrow",
  components: {
    ElementModal,
    ElementDetailsProduct,
    ElementList,
    ElementDetailsProgram,
    ElementDetailsPeople,
    SlideInMenu,
  },
  props: [
    'currentArrow',
    'currentArrowID',
    'arrowList',
    'currentUser',
    'translate',
  ],
  emits: ['save-arrow', 'delete-arrow', 'clear-arrow', 'create-arrow', 'set-colors', 'set-arrow'],
  data() {
    return {
      isShowingProduct: false,
      isShowingPurpose: false,
      isShowingPassion: false,
      isShowingArchive: false,
      isShowingColorpicker: false,
    };
  },
  setup( props, {emit} ) {


    const { currentUser } = toRefs(props);

    const { currentArrowID } = toRefs(props);
    watch(currentArrowID, () => {
       loadPeople()
       loadProgram()  
    }, { deep: true })

    const { loadElements: loadProgram, addElement: addProgram, deleteElement: deleteProgram, clearElements: clearProgram, archiveElement: archiveProgram, moveElement: moveProgram, saveElementsToDB: saveProgramToDB, ElementsConnect: ProgramConnect, ElementsWin: ProgramWin, ElementsBuild: ProgramBuild, ElementsTrain: ProgramTrain, ElementsSend: ProgramSend, ElementsArchive: ProgramArchive } = useElement("program", currentUser, currentArrowID)
    const { loadElements: loadPeople, addElement: addPeople, deleteElement: deletePeople, clearElements: clearPeople, archiveElement: archivePeople, moveElement: movePeople, saveElementsToDB: savePeopleToDB, ElementsConnect: PeopleConnect, ElementsWin: PeopleWin, ElementsBuild: PeopleBuild, ElementsTrain: PeopleTrain, ElementsSend: PeopleSend, ElementsArchive: PeopleArchive } = useElement("people", currentUser, currentArrowID)
    loadPeople()
    loadProgram() 

    const isShowingPeople = ref(false)
    const isShowingProgram = ref(false)
    const displayedElement = ref({})

    function clearArrow() {
      emit("clear-arrow")
      clearProgram()
      clearPeople()
    }

    function newArrow() {
      emit("create-arrow")
      clearProgram()
      clearPeople()
    }

    function showDetails(element, type) {
      if (type == 'people') {
        displayedElement.value = element;
        isShowingPeople.value = true;
      }
      else if (type == 'program') {
        displayedElement.value = element;
        isShowingProgram.value = true;
      }
    }


    function changeBackgroundColor(event) {
        let color = getComputedStyle(event.target).backgroundColor
        emit("set-colors", color, "")
    }
    function changeFontColor(event) {
          let color = getComputedStyle(event.target).backgroundColor
          emit("set-colors", "", color)
    }

    
    return {
      PeopleConnect, PeopleWin, PeopleBuild, PeopleTrain, PeopleSend, PeopleArchive ,
      ProgramConnect, ProgramWin, ProgramBuild, ProgramTrain, ProgramSend, ProgramArchive,
      addPeople, 
      deletePeople,
      archivePeople,
      movePeople,
      addProgram, 
      deleteProgram, 
      archiveProgram, 
      moveProgram, 
      showDetails,
      isShowingPeople,
      isShowingProgram,
      displayedElement,
      changeBackgroundColor,
      changeFontColor,
      clearArrow,
      newArrow,
    };
  },
};
</script>

<style >
:root {
  --top-product: 435px;
}

.arrow {
  background-color: var(--arrow-background-color);
  min-height: var(--top-steps_container);
  padding-top: 20px;
}

.process,
.target,
.product {
  color: var(--arrow-background-color);
  font-weight: bold;
}
.purpose,
.passion,
.product h2,
.product p {
  cursor: pointer;
}
.target h2,
.product h2 {
  font-size: 1rem;
  margin: 0 0 0 20px;
}
.purpose h2 span,
.passion h2 span,
.product h2 span {
  visibility: hidden;
}
.purpose:hover h2 span,
.passion:hover h2 span,
.product:hover h2 span {
   visibility: visible;
}
.target p,
.product p {
  font-weight: normal;
}
.target {
  position: relative;
  width: 50vw;
  height: 50vw;
  max-width: 300px;
  max-height: 300px;

  background-color: var(--arrow-main-color);
  border-radius: 50%;
  padding: 70px;
  margin: auto;
  
  border: solid var(--arrow-main-color) 15px;
  box-shadow: 0px 0px 0px 15px var(--arrow-background-color) inset;  
}

.target p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.product_divider {
  width: 70%;
  height: 200px;
  top: calc(var(--top-product) - 18px);
  left: 15%;
  position: absolute; 
  background-image:
        linear-gradient(to top left, var(--arrow-background-color) 0 50%, transparent 50% 100%),
        linear-gradient(to top right, var(--arrow-background-color) 0 50%, transparent 50% 100%);
    background-size:
        50% 100%, 50% 100%;
    background-position:
        left top, right top, center top;
    background-repeat: no-repeat;
}

.product {
  width: 40%;
  height: 120px;
  top: var(--top-product);
  left: 15%;
  padding: 80px 15% 0 15%;
  position: absolute;
  border-bottom: 25px solid var(--arrow-main-color);
  background-image:
        linear-gradient(to top left, var(--arrow-main-color) 0 50%, transparent 50% 100%),
        linear-gradient(to top right, var(--arrow-main-color) 0 50%, transparent 50% 100%);
    background-size:
        50% 100%, 50% 100%;
    background-position:
        left top, right top, center top;
    background-repeat: no-repeat;
}

.product p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.steps_container {
  width: 100%;
  max-width: 900px;
  position: relative;
  margin-top: 83px;
  left: 0;
  background-color: var(--arrow-background-color);
  background-image:
        linear-gradient(to top left, var(--arrow-main-color) 0 50%, transparent 50% 100%),
        linear-gradient(to top right, var(--arrow-main-color) 0 50%, transparent 50% 100%),
        linear-gradient(var(--arrow-main-color) 0 100%);
    background-size:
        20% 100%, 20% 100%, 60% 100%;
    background-position:
        left top, right top, center top;
    background-repeat: no-repeat;
}

.step {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 150px; 
}

.step:before {
  position: absolute;
  top: -20px;
  left:50%;
  margin-left: -30px;
  content:"";
  display:block;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 20px solid var(--arrow-main-color);
  filter:brightness(1.06);
}

.step.send {
  width: 60%;
  margin-left: 20%;
}
.step.train {
  width: 68%;
  margin-left: 16%;
}
.step.build {
  width: 76%;
  margin-left: 12%;
}
.step.win{
  width: 84%;
  margin-left: 8%;
}
.step.connect {
  width: 92%;
  margin-left: 4%;
}

.step_divider {
  height: 15px;
  background: var(--arrow-background-color);
  position: relative;
}
.step_divider:before {
  position: absolute;
  top: -20px;
  left:50%;
  margin-left: -30px;
  content:"";
  display:block;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 20px solid var(--arrow-background-color); 
}

.peopleList,
  .programList {
    width: calc(50% - 30px);
    padding: 10px;
  }

.process {
    width:20px;
    padding: 20px;
    background: var(--arrow-main-color);
    filter:brightness(1.06);
    transform: rotate(.5turn);
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-align: center;
}

.button {
  background: var(--arrow-main-color);
  border: 0;
  padding: 10px 20px;
  margin: 5px;
  color: white;
  border-radius: 3px;
}
.arrow_nav {
  background-color: var(--arrow-background-color);
}
.nav_item input {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1.3rem;
  font-weight: 700;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: text;
}
.nav_item input::placeholder{
  color: white;
}

.colorpickerButton{
  width: 32px;
  height: 32px;
  border-radius: 3px;
  margin: 5px;
  float: left;
  cursor: pointer;
}

.colopicker_1 {
  background-color: #dcdcdc;
}
.colopicker_2 {
  background-color: #282728;
}
.colopicker_3 {
  background-color: #AE1857;
}
.colopicker_4 {
  background-color: #4a94a1;
}
.colopicker_5 {
  background-color: #106470;
}
.colopicker_6 {
  background-color: #FF9231;
}
.colopicker_7 {
  background-color: #4B5656;
}
.colopicker_8 {
  background-color: #8a47ad;
}
.colopicker_9 {
  background-color: #328926;
}

#infobutton {
position: relative;
    background: transparent;
    padding: 7px 0;
    margin: 3px;
    font-size: 0.7rem;
    border-radius: 100%;
    color: black;
    vertical-align: super;
    border: solid;
    z-index: 11;
}

.tooltip {
  position:relative;
}

.tooltip:before,
.tooltip:after {
  position: absolute;
  content: '';
  opacity: 0;
  transition: all 0.4s ease;
}

.tooltip:before {
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: hsla(0,0%,100%,.9) transparent transparent transparent;
  bottom: 24px;
  transform: translateY(20px);
  z-index: 10;
  left: -21px;
}

.tooltip:after {
  content: attr(tooltip); 
  color: #2c3e50;
  background: hsla(0,0%,100%,.9);
  width: 160px;
  height: fit-content;
  font-size: 0.8rem;
  font-weight: normal;
  bottom: 34px;
  left: -35px;
  padding: 10px;
  border-radius: 5px;
  transform: translateY(20px);
  z-index: 10;
}

#infobutton:hover + span.tooltip:before,
#infobutton:hover + span.tooltip:after {
  opacity: 1;
  transform: translateY(-2px);
}

@media (min-width: 900px) {
  .product, 
  .product_divider {
    width: 360px;
    padding-left: 135px;
    padding-right: 135px;
    left: calc(50% - 315px);
  }
  .steps_container {
    left: calc(50% - 450px);
  }
}

@media (max-width: 700px) {
  .product_divider {
    top: calc(var(--top-product)  - 19px); 
  }
}

@media (max-width: 600px) {
  :root {
    --top-product: calc(50vw + 135px);
}
  .peopleList, 
  .programList{
    padding: 5px;
  }
  .process {
    padding: 10px;
  }
  .step_divider:before {
    top: -13px;
    margin-left: -20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 13px solid var(--arrow-background-color);
  }
  .step:before {
    top: -13px;
    margin-left: -20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 13px solid var(--arrow-main-color);
  }
}

@media (max-width: 500px) {
  .target{
    padding: 50px;
  }
  :root {
   --top-product: calc(50vw + 140px);
  }
  .product {
    height: 80px;
  }
  .product_divider {
    height: 160px;
    top: calc(var(--top-product)  - 21px); 
  }
}

@media (max-width: 350px) {
  .target{
    padding: 40px;
  }
  :root {
    --top-product: calc(50vw + 140px);
  }
  .product {
    height: 60px;
  }
  .product_divider {
    height: 140px;
  }
}



</style>