
<template>
    <div class="slideinmenu_container" :class="{ display_backdrop: display_backdrop }" @click.self="display_backdrop ? this.$emit('close') : null"> 
        <div class="clo_slideinmenu" :class="align" >
            <div class="menu_header" v-if="display_backdrop">
              <p><slot name="title"></slot></p>
              <div class="closebutton" @click="this.$emit('close')">x</div>
            </div>
            <div class="divider clear"></div>
  
            <div class="content">
              <slot name="content"></slot>
            </div>
  
        </div>
   </div>
  </template>
  
  <script>
  export default {
    props: [
      'align',
      'display_backdrop',
    ],
  };
  </script>
  
  <style >
  .slideinmenu_container {
    top: 0;
    right: 0;
    position: fixed;
    background: transparent;
    visibility: hidden;
    z-index: 16;
    width: 260px;
    min-height: 50px;
  }
  .slideinmenu_container.active {
    visibility: visible;
  }
  .slideinmenu_container.display_backdrop {
    width: 100%;
    height: 100%;
  }
  
  .display_backdrop .clo_slideinmenu {
      height: 100%;
  }
  .slideinmenu_container.keep_menu_open.display_backdrop {
      width: 0%;
  }
  
  .clo_slideinmenu {
      padding: 20px 20px 20px 20px;
      background-color: #f4f5f7;
      position: fixed;
      top: 46px;
      right: -270px;
      width: 220px;
      overflow: scroll;
      z-index: 10;
      box-shadow: 0 12px 24px -6px rgba(9,30,66,.25),0 0 0 1px rgba(9,30,66,.08);
      transition: 0.3s; 
  }
  
  .keep_menu_open.display_backdrop .clo_slideinmenu {
      top: 0;
  }
  
  .active .clo_slideinmenu {
      right: 0px;
      transition: 0.3s;
  } 
  
  .clo_slideinmenu.left {
      left: -270px;
      transition: 0.3s;
  }
  .active .clo_slideinmenu.left {
      left: 0px;
      transition: 0.3s;
  }
  
  .clo_slideinmenu .menu_header {
      height: 48px;
      padding: 0;
      width: 100%; 
      display: flex;
      flex-direction: row;
      align-items: center;
  }
  .clo_slideinmenu .menu_header p {
    color: var(--main-color);
    padding: 10px 25px 0 25px;
    width: 100%;
    font-weight: bold;
    font-size: 1.3rem;
  }
  .clo_slideinmenu .menu_header .closebutton {
    padding: 0;
    color: grey;
    position: absolute;
    top: 9px;
    right: 26px;
    width: 20px;
    height: 20px;
    font-size: 1.6rem;
    cursor: pointer;
  }
  
  .keep_menu_open .clo_slideinmenu .menu_header .closebutton {
    display: none;
  }
  .clo_slideinmenu .content {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .clo_slideinmenu h2 {
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: 20px;
  }
  .clo_slideinmenu .archive-item, .clo_slideinmenu .list-item  {
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 1px 0 rgb(9 30 66 / 25%);
      cursor: pointer;
      padding: 5px;
      margin: 5px 5px 0 5px;
  }
  .clo_slideinmenu .archive-item:hover, .clo_slideinmenu .list-item:hover {
      background-color: hsl(228, 14%, 97%);     
  }
  .clo_slideinmenu .delete {
    float: right;
    margin-right: 5px;
  }
  .clo_slideinmenu span.link {
    color: #5e6c84;
    text-decoration: underline;
    text-decoration-color: #5e6c84;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .clo_slideinmenu div.link_bottom {
    color: #5e6c84;
    text-decoration: underline;
    text-decoration-color: #5e6c84;
    font-size: 0.8rem;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 220px;
  }
  .clo_slideinmenu div.link_bottom:hover,
  .clo_slideinmenu span.link:hover {
    color: black;
    text-decoration-color: black;
  
  }
  
  .clo_slideinmenu .button {
    background-color: var(--header-background-color);
  }
  .clo_slideinmenu .divider {
    background-color: rgba(9, 30, 66, 0.13);
    content: " ";
    display: block;
    height: 1px;
    margin: 8px auto;
  }
  .clo_slideinmenu .usersettings p {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    margin: 0;
    cursor: pointer;
  }
  .clo_slideinmenu .userimage {
      width: 40px;
      height: 40px;
      float: left;
      margin-right: 10px;
      font-size: 2rem;
  }
  .clo_slideinmenu .userimage img {
      width: 40px;
      height: 40px;
      object-fit: cover;
  }
  .clo_slideinmenu p.username {
      font-weight: 600;
      line-height: 20px;
      cursor: default;
  }
  .clo_slideinmenu p.mailadress {
    color: #B3BAC5;
    line-height: 20px;
    cursor: default;
  }
  
  
  .clo_slideinmenu label {
    vertical-align: middle;
  }
  
  
  
  
  
  
  .clo_slideinmenu .language_container {
    margin-bottom: 10px;
    text-align: left;
    font-size: 0.8rem;
  }
  .clo_slideinmenu input[type="radio"] {
    margin: -1px 5px 0 0;
    vertical-align: middle;
  }
  </style>