import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import { useFirebaseStorage } from 'vuefire'
import { ref as storageRef } from 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAjhqq_DpjFhHFgXyUplcjlLWSf1T3YFv8",
    authDomain: "shift-arrow.firebaseapp.com",
    databaseURL: "https://shift-arrow.firebaseio.com",
    projectId: "shift-arrow",
    storageBucket: "shift-arrow.appspot.com",
    messagingSenderId: "149944155635",
    appId: "1:149944155635:web:066d597acd949766516686",
    measurementId: "G-measurement-id"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();
const storage = useFirebaseStorage()


export { auth, db , storage, storageRef};

