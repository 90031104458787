<template>
    <div class="dashboard">
        <h2>{{ translate('Dashboard') }}</h2>
        <div class="arrows">
            <div v-for="arrow in arrowList" :key="arrow.ID">
                <div class="list-item " @click="$emit('set-arrow', arrow.ID)" :style="{ 'background-color': arrow.backgroundColor, 'color': arrow.fontColor }">
                    <div class="title">{{ arrow.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Dashboard',
    props: [
        'arrowList',
        'translate',
    ],
    emits: [ 'set-arrow', 'delete-arrow' ],
}
</script>

<style scoped>
h2 {
    color: var(--main-color);
}
.arrows {
    width: 96%;
    display: block;
    padding: 2%;
    min-height: 100%;
}
.list-item  {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgb(9 30 66 / 25%);
    cursor: pointer;
    width: 23%;
    height: 100px;
    float: left;
    margin: 0 1% 2% 1%;
}
.list-item:nth-of-type(4n) {
    margin-right: 0;
}
.list-item .title {
    font-size: 1rem;
    font-weight: 700;
    overflow: hidden;
    max-height: 40px;
    word-wrap: break-word;
    text-align: left;
    padding: 10px 20px 10px 10px;
    width: 90%;
}
</style>