import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Arrow from '../views/Arrow.vue'
import { auth } from "../firebaseDatabase"

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      Dashboard: Dashboard,
    } 
  },
  {
    path: '/arrow',
    name: 'Arrow',
    component: Arrow,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
