<template>

  <div class="home">

    <h1>{{ translate('Welcome') }}<span v-if="currentUser && currentUser.emailVerified" > {{this.currentUser.displayName}}</span></h1>
    <p>{{ translate('Create your arrow without login (only localy saved)') }}</p>
    <router-link to="/arrow" class="button">{{ translate('Try it out!') }}</router-link>
 
  </div>

</template>

<script>
export default {
  name: "Home",    
  props: [
        'arrowList',
        'translate',
    ],
};
</script>

<style scoped>
input {   
  margin-right: 20px; 
}

.button, button {
  background: var(--header-background-color);
}
.button:hover, button:hover {
  background: rgba(101, 179, 182, 0.8);
}
</style>
