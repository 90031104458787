import { db } from "../firebaseDatabase";
import { ref, computed } from 'vue';
import { watch } from "@vue/runtime-core";

export default function useElement(type, currentUser, currentArrowID) {

    const elements = ref([]);

    watch(elements, (newValue) => {
        if (currentUser.value) {  
            saveElementsToDB()
        }
        else {
            localStorage.setItem(type, JSON.stringify(newValue));
        }
    }, { deep: true });

    watch(currentUser, (newValue) => {
        if (newValue) {  
            saveElementsToDB()
        }
    }, { deep: true });

    async function loadElements() {
        try {
            if (currentUser.value) {
                await db.collection(type).doc(currentArrowID.value)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            elements.value = doc.data().elementsArrays;
                        }
                        else {
                            elements.value = []
                        }
                    })
                    .catch(error => {
                        console.log('loadElements: ', error)
                    })
            }
            else if (localStorage.getItem(type)) {
                elements.value = JSON.parse(localStorage.getItem(type));
                if (elements.value == '' || elements.value == undefined || elements.value.length == 0) {
                    elements.value = []
                }
            }
        }
        catch (error) {
                console.log('loadElements: ', error)
        }
    }

    
    async function saveElementsToDB() {
        let returnValue = false;
        try {
            if (currentArrowID.value != '0') {
                if (elements.value == undefined) {
                    elements.value = []
                }
                await db.collection(type).doc(currentArrowID.value).set({ elementsArrays: elements.value })
                    .then(() => {
                        console.log("Elements successfully saved!");
                        returnValue = true;
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }
        }
        catch (error) {
            console.log('saveElementsToDB: ', error)
        }
        return returnValue;
    }

    function addElement(phase, name) {
        try {
            let maxPosition = -1
            elements.value.forEach(element => {
                if (element.phase == phase) {
                    if (maxPosition < element.position) {
                        maxPosition = element.position
                    }
                }
            });

            if (type == "people") {
                elements.value.push({
                    id: Date.now().toString(),
                    phase: phase,
                    position: maxPosition + 1,
                    name: name,
                    description: "",
                    prophecies: [],
                    highlights: [],
                    nextsteps: [],
                    nextcalls: [],
                    checkedSkills: [],
                });
            }
            else if (type == "program") {
                elements.value.push({
                    id: Date.now().toString(),
                    phase: phase,
                    position: maxPosition + 1,
                    name: name,
                    description: "",
                    goal: "",
                    interval: "",
                });
            }
        }
        catch (error) {
            console.log('addElement: ', error)
        }
    }

    function deleteElement(id) {
        let phase = elements.value.find(element => element.id == id).phase;

        //delete element
        elements.value = elements.value.filter((item) => {
            return item.id != id;
        });

        //reset positions
        let list = filterElementList(phase)
        for (let i = 0; i < list.length; i++) {
            list[i].position = i;
        }
    }

    function clearElements() {
        elements.value = [];
    }

    function archiveElement(id) {
        let phase = elements.value.find(element => element.id == id).phase;

        //delete element
        elements.value.find(element => element.id == id).phase = "archive";

        //reset positions
        let list = filterElementList(phase)
        for (let i = 0; i < list.length; i++) {
            list[i].position = i;
        }
    }
    
    function moveElement(movedElement, newPhase, oldPhase, newIndex, oldIndex) {
        let list = filterElementList(newPhase)
        let i = 0
       
        list.forEach(element => {
            if (newPhase === oldPhase && newIndex > oldIndex) {
                if (element.position === oldIndex) {
                    i--
                }
                if (element.position === (newIndex + 1)) {
                    i++
                }
            }
            else {
                if (element.position === newIndex) {
                    i++
                }
            }
            element.position = i
            i++  
        });

        let found = elements.value.find(element => element.id == movedElement.id);
        found.phase = newPhase;
        found.position = newIndex;

        list = filterElementList(newPhase)
        for (let i = 0; i < list.length; i++) {
            list[i].position = i;
        }

        //reset positions in old phase
        list = filterElementList(oldPhase)
        for (let i = 0; i < list.length; i++) {
            list[i].position = i;
        }
    }


    function filterElementList(phase) {
        let list = elements.value.filter((item) => {
            return item.phase === phase;
        });
        list.sort(function (a, b) { return a.position - b.position });
        return list;
    }

    const ElementsConnect = computed(() => {
        return filterElementList("connect");
    });
    const ElementsWin = computed(() => {
        return filterElementList("win");
    });
    const ElementsBuild = computed(() => {
        return filterElementList("build");
    });
    const ElementsTrain = computed(() => {
        return filterElementList("train");
    });
    const ElementsSend = computed(() => {
        return filterElementList("send");
    });
    const ElementsArchive = computed(() => {
        return filterElementList("archive");
    });
 

    return {
        loadElements,
        addElement,
        deleteElement,
        moveElement,
        archiveElement,
        clearElements,
        saveElementsToDB,
        ElementsConnect,
        ElementsWin,
        ElementsBuild,
        ElementsTrain,
        ElementsSend,
        ElementsArchive
    };
}
  
