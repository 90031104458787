import { computed, ref } from 'vue';
import { db } from "../firebaseDatabase";
import { watch } from "@vue/runtime-core";


export default function useTranslation(user) {

    const defaultLanguage = "DE"
    const currentLanguage = ref(defaultLanguage)
    const translations = ref([
        { "EN": "Loading...", "DE": "Laden..." },
        { "EN": "All Arrows", "DE": "Alle Pfeile"},
        { "EN": "Dashboard", "DE": "Dashboard" },
        { "EN": "Home", "DE": "Home" },
        { "EN": "Login", "DE": "Login"},
        { "EN": "Register", "DE": "Registrieren"},
        { "EN": "Forgot your Password", "DE": "Passwort vergessen"},
        { "EN": "Email Address", "DE": "Email"},
        { "EN": "Password", "DE": "Passwort"},
        { "EN": "Reset password", "DE": "Passwort zurücksetzen" },
        { "EN": "Name", "DE": "Name"},
        { "EN": "Always keep this menu open.", "DE": "Menu immer geöffnet lassen."},
        { "EN": "Don't keep this menu open.", "DE": "Menu nicht immer geöffnet lassen."},
        { "EN": "Account", "DE": "Benutzerkonto"},
        { "EN": "Profile", "DE": "Profil"},
        { "EN": "Email", "DE": "Email"},
        { "EN": "Change Email", "DE": "Email ändern"},
        { "EN": "Change Password", "DE": "Passwort ändern"},
        { "EN": "Language", "DE": "Sprache"},
        { "EN": "English", "DE": "Englisch"},
        { "EN": "German", "DE": "Deutsch"},
        { "EN": "Logout", "DE": "Abmelden"},
        { "EN": "User Profile", "DE": "Benutzerprofil"},
        { "EN": "Remove", "DE": "Entfernen"},
        { "EN": "Change", "DE": "Ändern"},
        { "EN": "Select", "DE": "Auswählen"},
        { "EN": "Save", "DE": "Speichern"},
        { "EN": "Reauthentication", "DE": "Authentifizierung"},
        { "EN": "This operation is sensitive and requires recent authentication.", "DE": "Dieser Vorgang ist sensibel und erfordert eine aktuelle Authentifizierung."},
        { "EN": "Reauthenticate", "DE": "Authentifizieren"},
        { "EN": "Old Password", "DE": "Altes Passwort"},
        { "EN": "New Password", "DE": "Neues Passwort"},
        { "EN": "Wrong Password", "DE": "Falsches Passwort"},
        { "EN": "There's no user with this email.", "DE": "Es gibt keinen Benutzer mit dieser Emailadresse."},
        { "EN": "Someting went wrong:", "DE": "Ein Fehler ist aufgetreten:"},
        { "EN": "Check your registered email to reset the password!", "DE": "Prüfen deine registrierte Email, um das Passwort zurückzusetzen!"},
        { "EN": "User successfully updated.", "DE": "Benutzer wurde erfolgreich aktualisiert."},
        { "EN": "An error occured.Please try again.", "DE": "Ein Fehler ist aufgtreten. Bitte versuche es später noch einmal."},
        { "EN": "User email successfully updated.", "DE": "Email des Benutzers wurde erfolgreich aktualisiert."},
        { "EN": "Password successfully updated.", "DE": "Passwort wurde erfolgreich aktualisiert." },
        { "EN": "Please confirm your email address.", "DE": "Bitte bestätige deine Email-Adresse."},
        { "EN": "Resend confirmation email.", "DE": "Bestätigungs Email wurde nochmals versendet."},

        { "EN": "My Arrow", "DE": "Mein Pfeil"},
        { "EN": "Describe your product", "DE": "Beschreibe dein Produkt"},
        { "EN": "Purpose & Passion", "DE": "Mission & Motivation"},
        { "EN": "What is your purpose and passion?", "DE": "Was ist deine Mission und Motivation?"},
        { "EN": "Passion", "DE": "Motivation"},
        { "EN": "Purpose", "DE": "Mission"},
        { "EN": "Describe your purpose", "DE": "Beschreibe deine Mission"},
        { "EN": "Describe your passion", "DE": "Beschreibe deine Motivation"},
        { "EN": "Color", "DE": "Farbe"},
        { "EN": "New", "DE": "Neu"},
        { "EN": "Archives", "DE": "Archiv"},
        { "EN": "Archive", "DE": "Archivieren"},
        { "EN": "Clear", "DE": "Löschen"},
        { "EN": "Program", "DE": "Methoden"},
        { "EN": "People", "DE": "Menschen"},
        { "EN": "Product", "DE": "Produkt"},
        { "EN": "CONNECT", "DE": "CONNECT"},
        { "EN": "WIN", "DE": "WIN"},
        { "EN": "BUILD", "DE": "BUILD"},
        { "EN": "TRAIN", "DE": "TRAIN"},
        { "EN": "SENND", "DE": "SENND"},
        { "EN": "Arrow Color", "DE": "Pfeilfarbe"},
        { "EN": "Background Color", "DE": "Hintergrundfarbe"},
        { "EN": "Change Color", "DE": "Farbe ändern"},
        { "EN": "Delete", "DE": "Löschen"},
        { "EN": "Restore", "DE": "Wiederherstellen" },
        { "EN": "Welcome", "DE": "Willkommen"},
        { "EN": "Create your arrow without login (only localy saved)", "DE": "Erstelle deinen Pfeil ohne Login (wird nur lokal gespeichert)"},
        { "EN": "Try it out!", "DE": "Probiere es aus!"},
        { "EN": "Description", "DE": "Beschreibung"},
        { "EN": "Prophecies", "DE": "Prophetien"},
        { "EN": "Add", "DE": "Hinzufügen"},
        { "EN": "Next Steps", "DE": "Nächste Schritte"},
        { "EN": "Next Calls", "DE": "Nächste Rufe"},
        { "EN": "New Prophecy", "DE": "Neue Prophetie"},
        { "EN": "New Step", "DE": "Neuer Schritt"},
        { "EN": "New Call", "DE": "Neuer Ruf" },
        { "EN": "Highlights", "DE": "Highlights" },
        { "EN": "New Highlight", "DE": "Neues Highlight" },
        
        { "EN": "Activity / History", "DE": "Aktivität / History"},
        { "EN": "Actions", "DE": "Aktionen"},
        { "EN": "Skills of a Desciplemaker", "DE": "Fähigkeiten eines Jüngermachers"},
        { "EN": "Programs", "DE": "Programme"},
        { "EN": "New Item", "DE": "Neues Element"},
        { "EN": "Goal (Mainpurpose)", "DE": "Ziel (Hauptzweck)"},
        { "EN": "Interval", "DE": "Intervall"},
        { "EN": "Select", "DE": "Wähle"},
        { "EN": "weekly", "DE": "wöchentlich"},
        { "EN": "every other week", "DE": "jede zweite Woche"},
        { "EN": "monthly", "DE": "monatlich"},
        { "EN": "once a year", "DE": "jährlich"},
        { "EN": "other", "DE": "anderes"},
        { "EN": "Skills", "DE": "Fähigkeiten"},
        { "EN": "New Skill", "DE": "Neue Fähigkeit" },
        { "EN": "This email adress is already in use.", "DE": "Diese Email-Adresse wird bereits verwendet." },
        { "EN": "Please enter a name.", "DE": "Bitte gib einen Namen an."},
        { "EN": "An error occured: ", "DE": "Ein Fehler ist aufgetreten: "},
        { "EN": "Wrong password", "DE": "Falsches Passwort."},
        { "EN": "Password should be at least 6 characters.", "DE": "Das Passwort muss mindestens 6 Zeichen haben."},
        { "EN": "The email address is badly formatted.", "DE": "Die Email-Adresse ist falsch formatiert."},
        { "EN": "Delete account", "DE": "Konto löschen"},
        { "EN": "Cancel", "DE": "Abbrechen"},
        { "EN": "Are you shure, you want to delete your account? All your data will be deleted. This can not be undone.", "DE": "Bist du sicher, dass du dein Konto löschen möchtest? Alle deine Daten werden dann gelöscht. Dies kann nicht rückgängig gemacht werden."},
        { "EN": "Profile Settings", "DE": "Profil Einstellungen"},
        { "EN": "Suggested Programs", "DE": "Vorgeschlagene Programme"},
        { "EN": "New Person", "DE": "Neue Person"},
        { "EN": "New Program", "DE": "Neue Methode"},

    
        

    ]);

    watch(user, () => {
        initTranslations();
    });

    async function initTranslations() {
        currentLanguage.value = defaultLanguage;
        if (user.value) {
            try {
                currentLanguage.value = await db.collection("users").doc(user.value.uid)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            return doc.data().language;
                        }
                        else {
                            return defaultLanguage;
                        }
                    })
                    .catch(error => {
                        console.log("initTranslations: ", error);
                    });
            }
            catch (error) {
                console.log('initTranslations: ', error);
            }
        }
        else if (localStorage.getItem("language")) {
            currentLanguage.value = JSON.parse(localStorage.getItem("language"));
        }
        if (currentLanguage.value == undefined || currentLanguage.value == '') {
            currentLanguage.value = defaultLanguage;
        }
    }

    function saveLanguage(userID, selectedLanguage) {
        currentLanguage.value = selectedLanguage

        if (userID != "") {
            db.collection("users").doc(userID)
                .update({
                    language: currentLanguage.value,
                })
                .then(() => {
                    console.log("Language saved");
                })
                .catch(error => {
                    console.log("saveLanguage: ", error);
                })
        }
    }

    function getTranslation(textEN) {
        let transaltedText = textEN;
        let currentItem = translations.value.filter((item) => {
            return item.EN === textEN;
        });
        if (currentItem.length > 0) {
            if (currentItem[0][currentLanguage.value] != "") {
                transaltedText =  currentItem[0][currentLanguage.value]
            }
        }
        return transaltedText;
    }

    const translate = computed(() => {
        return textEN => { return getTranslation(textEN) }
    });

    const language = computed(() => {
        return currentLanguage.value
    });

    return {
        initTranslations,
        saveLanguage,
        language,
        translate,
    };
}
  
