<template>

    <nav class="clo_nav main_nav">
     <div class="nav_left">
       <div class="nav_item" v-if="!currentUser && !isHome">
           <router-link to="/" :data-tooltip="translate('Home')">&nbsp;<font-awesome-icon icon="home" />&nbsp;</router-link>
       </div>
 
       <div class="nav_item" v-if="currentUser && currentUser.emailVerified">
           <router-link to="/dashboard" :data-tooltip="translate('Dashboard')"><font-awesome-icon icon="home" /><span>{{ translate('Dashboard') }}</span></router-link>
       </div>
       <div class="nav_item" v-if="currentUser && currentUser.emailVerified && !keepMenuOpen"  :data-tooltip="translate('All Arrows')" @click="() => { this.isShowingArrows = true;} ">
           <span><font-awesome-icon icon="arrow-right" /></span><p>{{ translate('All Arrows') }}</p>
       </div>
     </div>
     <div class="nav_center">
       <img src="@/assets/SHIFTLogo.png" />
     </div>
 
     <div class="nav_right" v-if="currentUser && currentUser.emailVerified" >
         <div class="nav_item userprofile" @click="() => { this.isShowingProfileMenu = true;}" @mouseover="this.isShowingProfileMenu = true" @mouseleave="this.isShowingProfileMenu = false" >{{this.currentUser.displayName}}</div>
         <div class="nav_item userimage" v-if="currentUser.photoURL" >
             <img @click="() => { this.isShowingProfileMenu = true;} "  @mouseover="this.isShowingProfileMenu = true" @mouseleave="this.isShowingProfileMenu = false" :src=currentUser.photoURL alt="">
         </div>
         <div class="nav_item" v-else>
             <span @click="() => { this.isShowingProfileMenu = true;} "  @mouseover="this.isShowingProfileMenu = true" @mouseleave="this.isShowingProfileMenu = false" ><font-awesome-icon icon="user" /></span>
         </div>
     </div>
 
     <div class="nav_right" v-else >
           <div class="nav_item item_language" v-if="userlanguage=='DE'" >
             <p  @click="() => { this.userlanguage = 'EN'; this.$emit('save-selected-language', this.userlanguage) } ">EN</p>
           </div>
           <div class="nav_item item_language" v-if="userlanguage=='EN'" >
             <p  @click="() => { this.userlanguage = 'DE'; this.$emit('save-selected-language', this.userlanguage) } ">DE</p>
           </div>
          <div class="nav_item" :data-tooltip="translate('Login')" @click="() => { isShowingLogin = true; this.errorMessage = ''} " >
            <span><font-awesome-icon icon="sign-in-alt" /></span><p>{{ translate('Login') }}</p>
           </div>
           <div class="nav_item" :data-tooltip="translate('Register')" @click="() => { isShowingRegister = true;} ">
          <span><font-awesome-icon icon="user-plus" /></span><p>{{ translate('Register') }}</p>
          </div>
     </div>
   </nav>
 
   <ElementModal v-if="isShowingLogin" @close="isShowingLogin=false">
     <template v-slot:title>
       <h1>{{ translate('Login') }}</h1> 
     </template>
 
     <template v-slot:content>
             <form @submit.prevent="login">  
               <div class='errormessage' v-if="errorMessage">
                 <p>{{ errorMessage }}</p>
               </div> 
               <input type="email" v-model="email"  :placeholder="translate('Email Address')" />
               <input type="password" v-model="password"  :placeholder="translate('Password')" />
               <p class='forgotpassword'  @click="() => { isShowingForgotPassword = true; isShowingLogin=false; } ">{{ translate('Forgot your password') }}?</p>
               <div class="clear">&nbsp;</div>
               <button type="submit" >{{ translate('Login') }}</button>  
             </form>
     </template>
   </ElementModal>
 
   <ElementModal v-if="isShowingRegister" @close="closeRegistration">
     <template v-slot:title>
       <h1>{{ translate('Register') }}</h1> 
     </template>
 
     <template v-slot:content>
        <div v-if="modalMessage">
           <p>{{ modalMessage }}</p>
        </div>
        <div v-else>
           <form @submit.prevent="register">  
               <div class='errormessage' v-if="errorMessage">
                   <p>{{ errorMessage }}</p>
               </div>
 
               <input type="text" required v-model="username"  :placeholder="translate('Name')" />
               <input type="email" required v-model="email"  :placeholder="translate('Email Address')" />
               <input type="password" required v-model="password"  :placeholder="translate('Password')" />
               <div class="clear">&nbsp;</div>
 
               <h2>{{ translate('Language') }}</h2>
               <div class="language_container">
                   <input type="radio" id="EN" name="language" v-model="userlanguage" value="EN">
                   <label for="EN">{{ translate('English') }}</label>
                   <br>
                   <input type="radio" id="DE" name="language" v-model="userlanguage" value="DE">
                   <label for="DE">{{ translate('German') }}</label>
               </div>
 
               <button type="submit" >{{ translate('Register') }}</button>    
           </form> 
        </div>
     </template>
   </ElementModal>
 
     <ElementModal v-if="isShowingEmailconfirmation" @close="isShowingEmailconfirmation=false">
     <template v-slot:title>
       <h1>{{ translate('Register') }}</h1> 
     </template>
 
     <template v-slot:content>
           <p>{{ translate('Please confirm your email address.') }}</p>
           <form @submit.prevent="confirmEmail">  
               <button type="submit" >{{ translate('Resend confirmation email.') }}</button>    
           </form> 
           <div v-if="modalMessage">
               <p>{{ modalMessage }}</p>
           </div>
     </template>
   </ElementModal>
 
    <SlideInMenu align="left" :display_backdrop="true" :class="{ active: isShowingArrows, keep_menu_open: keepMenuOpen }" @close="isShowingArrows=false, this.$emit('close-open-menu')">
       <template v-slot:title>{{ translate('All Arrows') }}</template>
       <template v-slot:content>
         <div  v-for="arrow in arrowList" :key="arrow.ID">
           <p class="list-item " @click.self="this.$emit('set-arrow', arrow.ID)" :style="{ 'background-color': arrow.backgroundColor, 'color': arrow.fontColor }">{{ arrow.title }}<span class="delete" @click="this.$emit('delete-arrow', arrow.ID)"> x </span></p>
         </div>  
         <div v-if="!keepMenuOpen" @click="this.$emit('keep-menu-open')" class="link_bottom">{{ translate('Always keep this menu open.') }}</div>
         <div v-if="keepMenuOpen"  @click="this.$emit('keep-menu-open')" class="link_bottom">{{ translate("Don't keep this menu open.") }}</div>
       </template>
     </SlideInMenu>
 
     <SlideInMenu align="right" :display_backdrop="false" :class="{ active: isShowingProfileMenu }"  @mouseover="this.isShowingProfileMenu = true" @mouseleave="this.isShowingProfileMenu = false"  >
       <template v-slot:title>{{ translate('Account') }}</template>
       <template v-slot:content >
         <div v-if="currentUser" class="usersettings">
             <div v-if="currentUser.photoURL">
               <div class="userimage" @click="() => { isShowingProfileSettings = true; } " ><img :src=currentUser.photoURL alt=""></div>
             </div>
             <div v-else>
               <div class="userimage" @click="() => { isShowingProfileSettings = true; } " ><font-awesome-icon icon="user" /></div>
             </div>
             <div>
               <p class="username" @click="() => { isShowingProfileSettings = true; } " >{{ currentUser.displayName }}</p>
               <p class="mailadress"  @click="() => { isShowingProfileSettings = true; } ">{{ currentUser.email }}</p>
             </div>
           <div class="divider clear"></div>
           <p @click="() => { isShowingProfileSettings = true; } " >{{ translate('Profile Settings') }}</p>
           <p @click="() => { isShowingDeleting = true; } " >{{ translate('Delete account') }}</p>
           <div class="divider clear"></div>
           <div class="button" @click="logout">{{ translate('Logout') }}</div>
         </div>
       </template>
     </SlideInMenu>
 
   <ElementModal v-if="isShowingProfileSettings" @close="closeUserProfile">
     <template v-slot:title>
       <h1>{{ translate('User Profile') }}</h1> 
     </template>
  
     <template v-slot:content>
       <div class="clo_userprofil">
         <form @submit.prevent="updateUser">  
 
             <div v-if="userimage" class="userimage" >
               <img :src=userimage alt=""  @click="selectImage" >
               <br>
               <span class="button" @click="removeImage">{{ translate('Remove') }}</span>
               <span class="button" @click="selectImage">{{ translate('Change') }}</span>
 
            </div>
             <div v-else class="userimage">
               <font-awesome-icon icon="user"  @click="selectImage" />
               <br>
               <span class="button" @click="selectImage">{{ translate('Select') }}</span>
             </div>
             <input type="file" ref="inputUserImage" style="display: none" @change="changeImage" accept="image/*" >                
 
             <h2>{{ translate('Name') }}</h2>
             <input type="text" v-model="username"  :placeholder="translate('Name')" />
 
             <h2>{{ translate('Email') }}</h2>
             <input type="email"  v-model="email"  />    
 
             <h2>{{ translate('Change Password') }}</h2>
             <input type="password"  v-model="newPassword"  :placeholder="translate('New Password')" />
             <div class="clear">&nbsp;</div>
 
             <h2>{{ translate('Language') }}</h2>
             <div class="language_container">
               <input type="radio" id="EN" name="language" v-model="userlanguage" value="EN">
               <label for="EN">{{ translate('English') }}</label>
               <br>
               <input type="radio" id="DE" name="language" v-model="userlanguage" value="DE">
               <label for="DE">{{ translate('German') }}</label>
             </div>
 
             <div class='errormessage' v-if="errorMessage">
               <p>{{ errorMessage }}</p>
             </div> 
 
             <button @click="errorMessage=null; modalMessage=null;" type="submit" >{{ translate('Save') }}</button>      
                     
         </form> 
       </div>
     </template>
   </ElementModal>
 
   <ElementModal v-if="isShowingDeleting" @close="isShowingDeleting=false;">
     <template v-slot:title>
       <h1>{{ translate('Delete account') }}</h1> 
     </template>
 
     <template v-slot:content>
           <p>{{ translate('Are you shure, you want to delete your account? All your data will be deleted. This can not be undone.') }}</p> 
           <button @click="isShowingDeleting=false; ">{{ translate('Cancel') }}</button>      
           &nbsp;
           <button @click="deleteAccount">{{ translate('Delete account') }}</button>      
     </template>
   </ElementModal>
 
     <ElementModal v-if="isShowingReauthentication" @close="isShowingReauthentication=false; errorMessage=null">
     <template v-slot:title>
       <h1>{{ translate('Reauthentication') }}</h1> 
     </template>
 
     <template v-slot:content>
           <p>{{ translate('This operation is sensitive and requires recent authentication.') }}</p>
           <form @submit.prevent="reauthenticate">  
             <div class='errormessage' v-if="errorMessage">
               <p>{{ errorMessage }}</p>
             </div>
             <input type="password" required v-model="password"  :placeholder="translate('Password')" />
             <p class='forgotpassword'  @click="() => { isShowingForgotPassword = true; isShowingLogin=false; } ">{{ translate('Forgot your password') }}?</p>
             <button type="submit"  @click="errorMessage=null;" >{{ translate('Reauthenticate') }}</button>      
           </form>
     </template>
   </ElementModal>
 
   <ElementModal v-if="isShowingForgotPassword" @close="isShowingForgotPassword=false">
     <template v-slot:title>
       <h1>{{ translate('Forgot your password') }}</h1> 
       </template>
       <template v-slot:content>
             <div v-if="modalMessage">
               <p>{{ modalMessage }}</p>
             </div>
             <div v-else>
               <form @submit.prevent="forgetPassword">   
                 <div class='errormessage' v-if="errorMessage">
                   <p>{{ errorMessage }}</p>
                 </div>
                 <input type="email" v-model="email"  :placeholder="translate('Email address')" />
                 <div class="clear">&nbsp;</div>
                 <button type="submit" >{{ translate('Reset password') }}</button>  
               </form>
             </div>
       </template>
   </ElementModal>
 
 </template>
 
 
 <script>
 import ElementModal from './ElementModal'
 import SlideInMenu from './SlideInMenu'
 import { auth } from "../firebaseDatabase";
 import { db } from "../firebaseDatabase";
 import { storage } from "../firebaseDatabase";
 import { storageRef } from "../firebaseDatabase";

 export default {
     name: 'Header',
     components: {
         ElementModal,
         SlideInMenu,
     },
     data() {
       return {
         username: '',
         email: '', 
         password: '', 
         newPassword: '',
         userlanguage: this.language,
         isShowingLogin: false,
         isShowingRegister: false,
         isShowingArrows: false,
         isShowingProfileSettings: false,
         isShowingForgotPassword: false,
         isShowingReauthentication: false,
         isShowingDeleting: false,
         isShowingProfileMenu: false,
         isShowingEmailconfirmation: false,
         userimage: '',
         imageData: null,
         modalMessage: '',
         errorMessage: '',
       };
     },
     props: [
         'isHome',
         'arrowList',
         'currentUser',
         'language',
         'translate',
         'keepMenuOpen',
     ],
     emits: [ 'delete-arrow', 'delete-all-data', 'set-arrow', 'save-selected-language', 'keep-menu-open', 'close-open-menu', 'delete-all-arrows' ],
     mounted() {
         auth.onAuthStateChanged((user) => {
             if (user) {
               if (user.emailVerified) { // User is signed in
                 this.isShowingEmailconfirmation = false;
                 this.userimage = user.photoURL;
                 this.username = user.displayName;
                 this.email = user.email;
               }
               else {
                 this.isShowingEmailconfirmation = true;
               }
             }
             else {
               this.userimage = '';
               this.username = '';
               this.email = '';
             }
         });
     },
     methods: {
         login() {
           auth
             .signInWithEmailAndPassword(this.email, this.password)
             .then(() => {
               this.isShowingLogin = false;
               this.password = ""
               if (!auth.currentUser.emailVerified) {
                 this.isShowingEmailconfirmation = true;
               }
             })
             .catch(error => {
               console.log(error.code)
               if (error.code == 'auth/wrong-password') {
                 this.errorMessage = this.translate('Wrong Password');
               }
               else if (error.code == 'auth/user-not-found') {
                 this.errorMessage = this.translate("There's no user with this email.");
               }
               else {
                   console.log(error.message);
               }
             });
         },
         forgetPassword() {
             this.modalMessage = ''
             this.errorMessage = ''
             auth
             .sendPasswordResetEmail(this.email)
             .then(() => {
                 this.modalMessage = this.translate('Check your registered email to reset the password!');
                 this.email = ''
             }).catch(error => {
                 this.errorMessage = this.translate('An Error occured: ') + error.message;
             })
         },
         register() {
             this.errorMessage = ''
             this.modalMessage = ''
 
             auth
             .createUserWithEmailAndPassword(this.email, this.password)
             .then(() => {
                 auth.currentUser.updateProfile({
                   displayName: this.username,
                 })
                 .then(() => {
                     db.collection("users").doc(auth.currentUser.uid).set({
                       language: this.userlanguage,
                     })
                     .then(() => {
                         this.$emit('save-selected-language', this.userlanguage) 
                     })
                     .catch((error) => {
                         console.error("register: ", error);
                     });
                     
                     this.closeRegistration()
                     if (!auth.currentUser.emailVerified) {
                       this.isShowingEmailconfirmation = true;
                     }
                 })
                 .catch(error => {
                     console.log(error.message);
                 });
             })
             .catch(error => {
                 if (error.code == 'auth/email-already-in-use') {
                   this.errorMessage = this.translate('There is already an acount with this email');
                 }
                 console.log(error.message);
             });
         },
         confirmEmail() {
             this.modalMessage = ""
             auth.currentUser.sendEmailVerification()
                 .then(() => {
                   this.modalMessage = this.translate("Your confirmation Email has been sent to ") + auth.currentUser.email
                 })
                 .catch(error => {
                     console.log(error.message);
                 });
                 
         },
         logout() {
             auth
                 .signOut()
                 .then(() => {
                     this.isShowingProfileMenu = false;
                     this.$router.push('/');
                 })
                 .catch(error => {
                     console.log(error.message);
                     this.$router.push('/');
                 });
             this.$emit('delete-all-arrows') 
         },  
         async updateUser() {
             if (this.username.trim() == "") {
                 this.errorMessage = this.translate("Please enter a Name.")
             }
             else {
               if (auth.currentUser.displayName != this.username) {
                 await this.updateUserName();
               }
               if (this.newPassword != "") {
                 await this.updatePW();
               }
               if (auth.currentUser.email != this.email) {
                 await this.updateEmail();
               }
              this.currentUser.value = auth.currentUser;
               if (this.language != this.userlanguage) {
                 this.$emit('save-selected-language', this.userlanguage) 
               }
               //ein paar sekunden waten, bis sprache gespeichert wurde, damit gewählte in closeUserProfile nciht wieder überschrieben wird, bevor gespeichert
               if (!this.isShowingReauthentication) {
                 this.closeUserProfile()
               }
             }
         },      
         async updateUserImage() {          
                 let returnvalue = await auth.currentUser
                   .updateProfile({
                     photoURL: this.userimage
                   })
                   .then(() => {
                       this.modalMessage = this.translate('UserImage successfully updated.');
                   })
                   .catch(error => {
                       this.errorMessage = this.translate('An Error occured: ' + error.message);
                   });
                   return returnvalue;
         },
         async updateUserName() {          
                 let returnvalue = await auth.currentUser
                   .updateProfile({
                     displayName: this.username,
                   })
                   .then(() => {
                       this.modalMessage = this.translate('UserName successfully updated.');
                   })
                   .catch(error => {
                       this.errorMessage = this.translate('An Error occured: ' + error.message);
                   });
                   return returnvalue;
         },
         async updateEmail() {
               let returnvalue = await auth.currentUser
                 .updateEmail(this.email)
                 .then(() => {                      
                   this.modalMessage = this.translate('User successfully updated.');
                 })
                 .catch(error => {
                     switch(error.code) {
                       case 'auth/requires-recent-login':
                         this.isShowingReauthentication = true;
                         break;
                       case 'auth/email-already-in-use':
                         this.errorMessage = this.translate('This email adress is already in use.')
                         break;
                       case 'auth/invalid-email':
                         this.errorMessage = this.translate('The email address is badly formatted.')
                         break;
                       default:
                         this.modalMessage = this.translate('An Error occured: ') + error.message
                     }
                 });
                 return returnvalue;
         },
         async updatePW() {
             const user = auth.currentUser;
             let returnvalue = await user.updatePassword(this.newPassword).then(() => {
                 this.newPassword = ""
                 this.password = ""
                 this.modalMessage = this.translate('User successfully updated.');
             })
             .catch((error) => {
                 switch(error.code) {
                   case 'auth/requires-recent-login':
                     this.isShowingReauthentication = true;
                     break;
                   case 'auth/weak-password':
                     this.errorMessage = this.translate('Password should be at least 6 characters.')
                     break;
                   case 'auth/wrong-password':
                     this.errorMessage = this.translate('Wrong Password')
                     break;
                   default:
                     this.modalMessage = this.translate('An Error occured: ') + error.message
                 }
             }); 
             return returnvalue;
         },
         reauthenticate() {
                   var user = auth.currentUser;          
                   const credential = auth.EmailAuthProvider.credential(
                       user.email, 
                       this.password
                   );
                   user.reauthenticateWithCredential(credential)
                   .then(() => {
                     this.isShowingReauthentication = false;
                     if (this.isShowingDeleting) {
                       this.deleteAccount()
                     }
                     else if (this.isShowingProfileSettings) {
                       this.updateUser()
                     }
                   })
                   .catch(error => {
                     switch(error.code) {
                       case 'auth/wrong-password':
                         this.errorMessage = this.translate('Wrong Password')
                         break;
                       default:
                         this.modalMessage = this.translate('An Error occured: ') + error.message
                     }
                   });
         },
         selectImage() {
           this.$refs.inputUserImage.click()
         },
         changeImage(event) {
           this.removeImage()
           this.uploadValue = 0;
           this.imageData = event.target.files[0];
           this.onUploadImage()
 
           //this.updateUserImage()
           
         },
         onUploadImage(){
          this.userimage = null;
          storageRef = storage.ref(`${this.imageData.name}`).put(this.imageData);
          storageRef.on(`state_changed`,snapshot=>{
          this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            }, error=>{console.log(error.message)},
          ()=>{this.uploadValue = 100;
              storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                  this.userimage = url;
                  console.log(this.userimage)
                });
              }      
            );
        },
        removeImage(){
            if (this.userimage != "" && this.userimage != null) {
          //    const storage = firebase.storage(); 
              console.log(this.userimage)
              storageRef = storage.refFromURL(this.userimage);
    
              storageRef.delete()
                .then(() => {
                    this.userimage = "";
                })
                .catch(error => {
                    console.log(error.message)
                });
                 this.updateUserImage()
            }
        },
         deleteAccount(){
           this.removeImage()
           this.$emit("delete-all-data")
 
           const user = auth.currentUser;
           user.delete().then(() => {
             this.isShowingDeleting = false;
             this.isShowingProfileSettings = false;
             this.isShowingProfileMenu = false;
             this.$router.push('/');
           }).catch((error) => {
               switch(error.code) {
                       case 'auth/requires-recent-login':
                         this.isShowingReauthentication = true;
                         break;
                       default:
                         this.modalMessage = this.translate('An Error occured: ') + error.message
                     }
           });
         },
         closeUserProfile(){
             this.modalMessage=null; 
             this.errorMessage=null;
             this.username = this.currentUser.displayName;
             this.email = this.currentUser.email;
             this.newPassword = "";
             //this.userlanguage = this.language;
             this.isShowingProfileSettings=false; 
         },
         closeRegistration(){
             this.modalMessage=null; 
             this.errorMessage=null;
             this.username = "";
             this.email = "";
             this.password = "";
             //this.userlanguage = this.language;
             this.isShowingRegister=false; 
         },
     },
 }
 </script>
 
 <style>
 </style>