import { db } from "../firebaseDatabase";
import { auth } from "../firebaseDatabase";
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";


export default function useArrow(user, defaultArrow) {
    //default values
    const arrows = ref([]);
    const currentArrowID = ref('0')

    //watch arrowList
    watch(arrows, (newValue) => {
        if (newValue) {
            if (currentArrowID.value == 0) {
                let userID = ""
                if (user.value) {
                    userID = user.value.uid
                }
                currentArrowID.value = userID + "_" + Date.now().toString()

                let arrow = newValue.filter(
                    (item) => {
                        return item.ID == '0';
                    });
                if (arrow.length > 0) {
                    arrow[0].ID = currentArrowID.value;
                }
            }
            if (user.value) {
                saveArrowstoDB(user.value.uid)
            }
            else {
                localStorage.setItem("arrows", JSON.stringify(newValue));
            }
        }
    }, { deep: true });

    watch(currentArrowID, (newValue) => {
        if (newValue != undefined) {
            if (auth.currentUser) {
                saveCurrentArrowID(auth.currentUser.uid, newValue)
                displayColors()
            }
        }
    });

    watch(user, (newValue) => {

        var user = auth.currentUser
        if (user) {
            var signupDate = new Date(user.metadata.creationTime);

            if ((Date.now() - signupDate.valueOf()) < 10000) { //user just registered
                //save local arrow to new user
                arrows.value[0].ID = user.uid + currentArrowID.value 
                setCurrentArrowID(user.uid + currentArrowID.value)

                //delete arrow in LocalStorage
                localStorage.removeItem("arrows");
                localStorage.removeItem("program");
                localStorage.removeItem("people");
            }
        }
        initArrows();  
    });

    const initArrows = async () => {
        if (user.value) {
            arrows.value = await loadArrowsfromDB(user.value.uid)
            currentArrowID.value = await getCurrentArrowID(user.value.uid)
        }
        else if (localStorage.getItem("arrows")) {            
            arrows.value = JSON.parse(localStorage.getItem("arrows"));
            if (arrows.value != '' && arrows.value != undefined && arrows.value.length != 0) {
                currentArrowID.value = arrows.value[0].ID
            }
        }
        if (arrows.value == [] || arrows.value == undefined || arrows.value == '') {            
            arrows.value = [defaultArrow];
            currentArrowID.value = '0';
        }
        displayColors()  
    }
        
    function setColors(backgroundColor, fontColor) {
        if (backgroundColor != "") {
            currentArrow.value.backgroundColor = backgroundColor
        }
        if (fontColor != "") {
            currentArrow.value.fontColor = fontColor
        }
        displayColors()
    }
    
    function displayColors() {
          document.documentElement.style.setProperty("--arrow-background-color", currentArrow.value.backgroundColor)
          document.documentElement.style.setProperty("--arrow-main-color", currentArrow.value.fontColor)
      }

    async function getCurrentArrowID(userID) {
        let returnValue = await db.collection("users").doc(userID)
            .get()
            .then(doc => {
                if (doc.exists) {
                    return doc.data().currentArrowID;
                }
                else {
                    return 0;
                }
            })
            .catch(error => {
                console.log("getCurrentArrowID: ", error);
            })
        if (returnValue == undefined || returnValue == '') {
            returnValue = '0';
        }
        return returnValue;
    }

    function saveCurrentArrowID(userID, arrowID) {

        const usersRef = db.collection('users').doc(userID)
        usersRef.get()
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                    usersRef.update({
                        currentArrowID: arrowID,
                    })
                        .then(() => { console.log("currentArrowID updated") })
                        .catch(error => {
                            console.log("saveCurrentArrowID: ", error);
                        })
                } else {
                    usersRef.set({
                        currentArrowID: arrowID,
                    })
                        .then(() => { console.log("currentArrowID saved") })
                        .catch(error => {
                            console.log("saveCurrentArrowID: ", error);
                        })
                }
            });
    }

    function saveArrowstoDB(userID) {
        try {
            arrows.value.forEach(arrow => {
                if (arrow.ID == 0) {
                    arrow.ID = userID + "_" + Date.now().toString()
                }
                db.collection("arrows").doc(arrow.ID).set({
                    title: arrow.title,
                    backgroundColor: arrow.backgroundColor,
                    fontColor: arrow.fontColor,
                    passion: arrow.passion,
                    purpose: arrow.purpose,
                    product: arrow.product,
                    userID: userID,
                    isArchived: arrow.isArchived,
                })
                    .then(() => {
                        console.log("Document successfully updated!");
                    })
                    .catch((error) => {
                        console.error("saveArrowstoDB: ", error);
                    });
            });
        }
        catch (error) {
            console.log('saveArrowstoDB: ', error)
        }
    }

    function clearCurrentArrow() {
        let index = ''
        let i = 0
        arrows.value.forEach(element => {
            if (element.ID == currentArrowID.value) {
                index = i
            }
            i++
        })
        arrows.value[index] = defaultArrow
        arrows.value[index].ID = currentArrowID.value
        displayColors()
    }

    async function deleteArrow(id) {
        await db.collection("arrows")
            .doc(id)
            .delete()
            .then(() => {
                arrows.value = arrows.value.filter(function (element) { return element.ID !== id })
           
                console.log("Arrow successfully deleted!");
            })
            .catch(error => {
                console.error("deleteArrow: ", error);
            });   

        if (arrows.value.length == 0) {
            createArrow()
            setCurrentArrowID(arrows.value[0].ID)
            getcurrentArrow()
        }
        if (currentArrowID.value == id) {
            setCurrentArrowID(arrows.value[0].ID)
            getcurrentArrow()
        }
    }
    
    function deleteAllArrows() {
        arrows.value = [defaultArrow];
    }

    function deleteAllData() {
        try {
            arrows.value.forEach(arrow => {
                db.collection("arrows").doc(arrow.ID).delete()
                    .then(() => { })
                    .catch((error) => {
                        console.error("deleteAllData: ", error);
                    });
                
                    db.collection("people").doc(arrow.ID).delete()
                    .then(() => { })
                    .catch((error) => {
                        console.error("deleteAllData: ", error);
                    });
                
                    db.collection("program").doc(arrow.ID).delete()
                    .then(() => { })
                    .catch((error) => {
                        console.error("deleteAllData: ", error);
                    });
            });
            db.collection("users").doc(user.value.uid).delete()
            .then(() => { })
            .catch((error) => {
                console.error("deleteAllData: ", error);
            });
        }
        catch (error) {
            console.log('deleteAllData: ', error)
        } 
    }

    async function loadArrowsfromDB(userID) {
        let loadedArrows = [];
        if (userID != undefined) {
            await db.collection("arrows")
                .where("userID", "==", userID)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        loadedArrows.push({
                            ID: doc.id,
                            title: doc.data().title,
                            backgroundColor: doc.data().backgroundColor,
                            fontColor: doc.data().fontColor,
                            passion: doc.data().passion,
                            purpose: doc.data().purpose,
                            product: doc.data().product,
                            userID: doc.data().userID,
                            isArchived: doc.data().isArchived,
                        });
                    });
                })
                .catch(error => {
                    console.log("loadArrowsfromDB: ", error);
                });
        }
        return loadedArrows;
    }

    function getArrowList() {
        let list = arrows.value
        if (list.length > 0) {
            list.sort(function (a, b) {
                const titleA = a.title.toUpperCase();
                const titleB = b.title.toUpperCase();
          
                let comparison = 0;
                if (titleA > titleB) {
                    comparison = 1;
                } else if (titleA < titleB) {
                    comparison = -1;
                }
                return comparison;
            });
        }
        return list;
    }

    function getcurrentArrow() {
        let arrow;
        if (currentArrowID.value != 0) {
            arrow = arrows.value.filter(
                (item) => {
                    return currentArrowID.value == item.ID;
                });
            if (arrow.length > 0) {
                arrow = arrow[0]
            }
        }
        if (arrow == [] || arrow == null || arrow == undefined || arrow.length == 0 ) {
            arrow = defaultArrow; 
        }
        return arrow;
    }
    
    function createArrow() {
        let userID = "";
        if (user.value) {
            userID = user.value.uid
        }
        let newID = userID + "_" + Date.now().toString()

        arrows.value.push({
            ID: newID,
            title: defaultArrow.title,
            backgroundColor: defaultArrow.backgroundColor,
            fontColor: defaultArrow.fontColor,
            passion: defaultArrow.passion,
            purpose: defaultArrow.purpose,
            product: defaultArrow.product,
            userID: userID,
            isArchived: defaultArrow.isArchived,
        });
        setCurrentArrowID(newID)
        displayColors()
    }

    function setCurrentArrowID(id) {
        currentArrowID.value = id
    }
    
    function getcurrentArrowID() {

        let id
        id = currentArrowID.value
        return id

    }

    const currentID = computed(() => {
        return getcurrentArrowID();

        /*
        get: () => currentArrowID.value,
        set: val => { currentArrowID.value = val }
        */
    });
    
    const currentArrow = computed(() => {
        return getcurrentArrow();
    });
        
    const arrowList = computed(() => {
        return getArrowList();
    });
    
    return {
        initArrows,
        setCurrentArrowID,
        clearCurrentArrow,
        deleteArrow,
        deleteAllArrows,
        deleteAllData,
        createArrow,
        setColors,
        arrowList,
        currentArrow,
        currentID,
    };
}


