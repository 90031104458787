<template>
  <div id="app_content" :class="{ keep_menu_open: keepMenuOpen }">
     <Header :isHome="isHome"  :language="language"  :currentUser="currentUser" :translate="translate" :arrowList="arrowList" :keepMenuOpen="keepMenuOpen" @delete-arrow="deleteArrow" @delete-all-data="deleteAllData" @set-arrow="setArrow"  @save-selected-language="saveSelecetedLanguage" @keep-menu-open="menuOpen"  @close-open-menu="menuClose" @delete-all-arrows="deleteAllArrows" />
  
      <div v-if="isHome && !currentUser">
        <router-view :translate="translate"></router-view>
      </div>
      <div v-else>
        <div v-if="isLoading">{{ translate('Loading...') }}</div>
        <div v-else>
          <div v-if="isHome && currentUser.emailVerified">
               <router-view name="Dashboard" :arrowList="arrowList" :translate="translate" @delete-arrow="deleteArrow" @set-arrow="setArrow" ></router-view>
          </div>
          <div v-else>
            <router-view :arrowList="arrowList" :translate="translate" :currentArrow="currentArrow"  :currentArrowID="currentArrowID" :currentUser="currentUser" @delete-arrow="deleteArrow" @clear-arrow="clearCurrentArrow"  @create-arrow="createArrow"  @set-arrow="setArrow" @set-colors="setColors" ></router-view>
          </div>
        </div>
      </div>

  </div>

  <div v-if="isShowingLeaveWarning" class="backdrop" @click.self="this.$emit('close')">
    <div class="clo_modal">
      <div class="closebutton" 
      @click="this.$emit('close')">x</div>
        
     <h2>Seite wirklich verlassen?</h2>
    </div>
  </div>

</template>

<script>
import { ref, computed } from '@vue/reactivity'
import Header from './components/Header'
import useArrow from './composables/useArrow'
import useTranslation from './composables/useTranslation'
import { useRouter } from 'vue-router'
import { auth } from "./firebaseDatabase";
import { onMounted } from "vue";
//import { onBeforeRouteLeave } from 'vue-router'

export default {
  components: {
      Header
  },   
  data() {
    return {
      isShowingLeaveWarning: false,
    };
  },
  setup() {
    const router = useRouter()
    const keepMenuOpen = ref(false)
    const isLoading = ref(true)
    const currentUser = ref(null)

    onMounted(() => {
      /*
      window.addEventListener("beforeunload", function(event) {
        event.preventDefault(); 
         // This custom message will only be used in older browsers
        event.returnValue = "Do you really want to leave? Your changes may not be saved.";
      });
      */
    })
/*

    onBeforeRouteLeave((to, from) => {
     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  if (answer) {
    next()
  } else {
    next(false)
  }
    })

  */

    //listener to User state changed
    auth.onAuthStateChanged((user) => {  
      if (user) {
          // User is signed in
          currentUser.value = user
      }
      else {
          currentUser.value = null
      }
    })

    const { initTranslations, saveLanguage, language, translate } = useTranslation(currentUser);

    let userID = ""
    if (currentUser.value) {
      userID = currentUser.value.uid
    }
    const defaultArrow = {
        ID: '0',
        title: translate.value('My Arrow'),
        product: { description: translate.value(""), skills: [] },
        passion: { description: translate.value("") },
        purpose: { description: translate.value("") },
        //passion: { name: translate.value("Passion"), description: translate.value("") },
        //purpose: { name: translate.value("Purpose"), description: translate.value("") },
        backgroundColor: '#54a6bf',
        fontColor: '#b8e0ff',
        userID: userID,
        isArchived: false,
    };

    const { initArrows, setCurrentArrowID, clearCurrentArrow, deleteArrow, deleteAllArrows, deleteAllData, setColors, createArrow, arrowList, currentArrow, currentID: currentArrowID } = useArrow(currentUser, defaultArrow)    

    const loadData = async () => {
      isLoading.value = true
      await initArrows();
      await initTranslations();
      isLoading.value = false
    };
    loadData()

    function setArrow(id) {
        setCurrentArrowID(id)
        router.push('/arrow')
    }

    function saveSelecetedLanguage(language) {
     let uid = ""
     if (currentUser.value != null) {
       uid = currentUser.value.uid
     }
      saveLanguage(uid, language)
      //reload default text for current language
      defaultArrow.title = translate.value('My Arrow')
      defaultArrow.product.description = translate.value("")
      //defaultArrow.passion.name=  translate.value("Passion")
      //defaultArrow.purpose.name=  translate.value("Purpose")
      defaultArrow.passion.description = translate.value("")
      defaultArrow.purpose.description = translate.value("")
    }

    function menuOpen() {
          keepMenuOpen.value = !keepMenuOpen.value
    }

    function menuClose() {
          keepMenuOpen.value = false
    }




    const isHome = computed(() => {
            return (router.currentRoute.value.path == "/")
    });

    return {
      currentUser, 
      setArrow,
      saveSelecetedLanguage,
      clearCurrentArrow,
      deleteArrow, 
      deleteAllArrows,
      deleteAllData,
      createArrow,
      setColors,
      arrowList,
      currentArrow,
      currentArrowID,
      keepMenuOpen,
      isLoading,
      menuOpen,
      menuClose,      
      isHome,
      language, 
      translate,
    }
  },
 }

</script>

<style>
:root {
  font-size: 100%;

  --main-color: rgb(111, 112, 112);
  --background-color: rgb(200, 243, 245);
  --header-background-color: #4a94a1;
  --arrow-main-color: #4a94a1;
  --arrow-background-color: #dcdcdc;
}
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: var(--background-color);
}
#app_content {
  min-height: 100vh;
  margin-left: 0;
}
#app_content.keep_menu_open {
  margin-left: 260px;
}

.clo_nav {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: rgba(0,0,0,.15);
  background-color: var(--header-background-color);
  max-height: 40px;  
}
.nav_item svg {
  padding: 0 5px 0 5px;
}

@media (max-width: 900px) {

  .clo_nav {
    max-height: 26px;
  }
.nav_item {
  height: 28px!important;
}
.clo_slideinmenu{
  top:36px;
}
/*
  .nav_item p,
  .nav_item a,
  .nav_item.userprofile  {
    font-size: 12px;
  }
  .nav_item input {
    font-size: 16px!important;
  }*/
}

@media (max-width: 750px) {
  .nav_item input {
    width: 120px;
  }
}
@media (max-width: 600px) {
  .nav_item p,
  .nav_item a span {
    display: none;
  }
  .nav_item.item_language p {
    display:block;
  }
}

.nav_left {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    justify-content: flex-start;
}
.nav_center {
    flex-shrink: 0;
}
.nav_center img{
    height: 30px;
    margin: 5px 0 -5px 0;
}
.nav_right {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    justify-content: flex-end;
}

.clo_nav a , .clo_nav a:visited {
  color: white; 
  text-decoration: none;
}
.nav_item {
    border-radius: 3px;
    text-decoration: none;
    align-items: center;
    background-color: hsla(0,0%,100%,.3);
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    padding: 0 5px;
    cursor: pointer;
}
.nav_item:hover {
    background-color: hsla(0,0%,100%,.4);
}
.nav_left .nav_item {
    margin: 0 4px 0 0;
}
.nav_right .nav_item {
    margin: 0 0 0 4px ;
}
.nav_item.userprofile {
    background: none;
}
.nav_item.userimage {
    padding: 0 ;
    align-items: center;
}
.nav_item.userimage img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    object-fit: cover;
}

/* Dropdown for Useraccount */
.dropdownmenu, .drop-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
}
.dropdownmenu-item {
    display: inline-block;
    position: relative;
    padding: 6px 10px;
    color: #fff;
    display: block;
}
.drop-menu {
    display: none;
    position: absolute;
    background-color: #f4f5f7;
    color: #2c3e50;
    min-width: 200px;
    box-shadow: 0 12px 24px -6px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
    z-index: 20;
    border-radius: 2px;
    padding: 10px;
}
.drop-menu-item {
    width: 100%;
}
.dropdownmenu-item:hover .drop-menu {
    display: block;
}

input:focus {
  outline: none;
}
.clear {
  clear: both;
}

[data-tooltip] {
    position: relative;
}
[data-tooltip]::after {
  content: attr(data-tooltip);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
  display: block;  
  position: absolute; 
  bottom: -30px;
  left: 50%;
  width: auto;
  padding: 0 8px 0 8px;
  font-size: 0.8rem;
  z-index: 100;
  color: var(--main-color); 
  background-color: #e0e0e0;
  border: none;
  border-radius: 3px;  
  white-space: nowrap;
}
[data-tooltip]:hover::after {
  opacity: 1;
}

.button, button {
  margin-top: 20px;
  font-size: 0.8rem;
  background: var(--header-background-color);
  border: 0;
  padding: 10px 20px;
  color: white;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
}
.button:hover, button:hover {
  background: rgba(101, 179, 182, 0.8);
}

@media (max-width: 900px) {

:root {
  font-size: 90%;
}
}
@media (max-width: 600px) {

:root {
  font-size: 80%;
}
}
</style>
