<template>
    <div class="backdrop" @click.self="this.$emit('close')">
      <div class="clo_modal" :class="{ 'without-sidebar': !hasSidebar }">
        <div class="closebutton" 
        @click="this.$emit('close')">x</div>
          
        <slot name="title"></slot>
          <div class="modal_content">

          <div class="content">
            <slot name="content"></slot>
          </div>
    
          <div class="sidebar" v-if="!!hasSidebar">
            <slot name="sidebar"></slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() { 
      return { 
          hasSidebar: this.$slots['sidebar'],
      }; 
    },
  };
  </script>
  
  <style >
  .backdrop {
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 15;
    overflow: scroll;
  }
  .clo_modal {
  background-color: #f4f5f7;
  border-radius: 2px;
  margin: 48px auto 50px auto;
  padding: 30px;
  overflow: hidden;
  position: relative;
  max-width: 670px;
  width: 80%;
  z-index: 25;
  }
  .modal_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
 
  .clo_modal .content {
    margin: 0;
    padding: 0;
    flex-grow: 3; 
  }
  .clo_modal .sidebar {
    padding: 0;
    margin-top: 10px;
    max-width:180px;
    flex-grow: 1;
  }
  .clo_modal.without-sidebar .content {
    width: 100%;
  }

  .clo_modal input.title, .clo_modal h1 {
    color: var(--main-color);
    border: none;
    background: none;
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    padding: 0;
  }
  .clo_modal .closebutton {
  
    background: none;
    border: none;
    padding: 10px;
    color: grey;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
  }
  
  .clo_modal .sidebar_button {
  
    color: #5e6c84;
    background-color: rgba(9,30,66,.04);
    margin-bottom: 8px;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    height: 32px;
    padding: 6px 12px;
    text-align: left;
    font-size: 0.8rem;
    line-height: 20px;
    font-weight: bold;
  }
    
  .clo_modal .sidebar_button:h
  over {
    background-color: rgba(9,30,66,.08);
    color: black;
  }
  
  .clo_modal .sidebar ul {
    margin: 0;
    padding: 0 0 0 17px;
  }
  .clo_modal .sidebar li {
    margin: 0;
    color: #5e6c84;
    text-align: left;
    font-size: 0.8rem;
    line-height: 20px;
  }
  .clo_modal .sidebar ul.desciplemakerskills {
    list-style-type: none;
    padding: 0;
  }
  .clo_modal h2 {
    color: var(--main-color);
    margin: 35px 0 10px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  .clo_modal h3 {
    color: #5e6c84;
    margin: 30px 0 10px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  .clo_modal h4 {
    margin: 0;
  }
  .clo_modal textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
    border-radius: 3px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
  }
  .clo_modal input[type="checkbox"] {
    display: inline-block;
    margin: 0 10px 0 0;
    position: relative;
    top: 2px;
    border-radius: 2px;
    height: 16px;
    width: 16px;
    background-color: #fafbfc;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    text-align: center;
    cursor: pointer;
  }

  .clo_modal input,
  .clo_modal select {
    width: 100%;
    padding: 9px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
    border-radius: 3px;
    margin-bottom: 5px;
   /* -moz-appearance: none;
    -webkit-appearance: none;*/
  }
  .clo_modal select {
    width: 25%;
  }
  .clo_modal input.with-button.new-skill {
    width: calc(75% - 60px);
  }

  .clo_modal input.with-button {
    width: calc(100% - 48px);
    float: left;
  }
  .clo_modal input[type="radio"] {
    width: auto;
    margin: -4px 5px 0 0;
    vertical-align: middle;
  }
  .clo_modal .button {
    margin: 0;
    padding: 8px;
    font-size: 0.8rem;
    background: var(--arrow-background-color);
    float: right;
    width: 15%;
  }
  .clo_modal .button.add-button {
    width: 25px;
  }
  .clo_modal .pill {
    margin: 10px 0;
    padding: 8px;
    background: hsl(228, 14%, 93%);
    border-radius: 3px;
    font-size: 0.8rem;
    color: #777;
    text-align: left;
  }
  .clo_modal .delete {
    cursor: pointer;
    float: right;
  }
  .clo_modal .clear {
    clear: both;
    height: 0px;
  }
  .clo_modal .col {
    float: left;
    text-align: left;
  }
  .clo_modal .nextSteps {
    margin-top: 50px;
    clear: both;
  }
  
  /* User Profil */
  .clo_modal .flexboxcontainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
  }
  .clo_modal .flexboxitem.userimage {
      width: 80px;
      font-size: 4.3rem;
  }
  .clo_modal .userimage img {
      width: 80px;
      height: 80px;
      border-radius: 3px;
      object-fit: cover;
  }
  .clo_modal .userimage .icons {
      font-size: 1.3rem;
      float: left;
  }
  .clo_modal .profile_name h2 {
      margin: 0 0 10px 0;
  }
  .clo_modal .profile_name input {
      width: 400px; 
  }
  
  
  .clo_modal .language_container {
    margin-bottom: 10px;
    text-align: left;
    font-size: 0.8rem;
  }
  
  
  
  
  .clo_modal .forgotpassword {
      text-align: right;
      font-size: 0.7rem;
  }
  .clo_modal .forgotpassword:hover {
      text-decoration: underline;
  }
  .clo_modal .errormessage {
      color: red;
  }
  
  
  .clo_modal .clo_userprofil .userimage svg { 
      font-size: 5rem;
      margin-bottom: 5px;
  }
  .clo_modal .clo_userprofil .button {
      float: none;
      width: fit-content;
      margin: 5px;
      padding: 5px 10px;
      background: var(--header-background-color);
      cursor: default;
      font-size: 0.8rem;
  }

  </style>